import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faBold,
  faItalic,
  faUnderline,
  faList,
  faList12,
  faHighlighter,
  faCode,
} from "@fortawesome/free-solid-svg-icons";
import { BsSuperscript, BsSubscript } from "react-icons/bs";
import "../../assets/styles/textFormatter.css";
import ImageUploadAndExplanation from "./ImageUploadAndExplanation";

export default function TextFormatter({ editor, showFileUploadAndExplaination = false }) {
  const editorAvailable = editor ? true : false;

  return (
    <div className={`main-text-formatter ${!editorAvailable ? "editor-disabled" : ""}`}>
      <div className="text-style">
        <FontAwesomeIcon
          size="lg"
          icon={faBold}
          onClick={() => editorAvailable && editor.chain().focus().toggleBold().run()}
          className={editorAvailable && editor.isActive("bold") ? "is-active" : ""}
          title="Bold (Ctrl + B)"
        />
        <FontAwesomeIcon
          size="lg"
          icon={faItalic}
          onClick={() => editorAvailable && editor.chain().focus().toggleItalic().run()}
          className={editorAvailable && editor.isActive("italic") ? "is-active" : ""}
          title="Italic (Ctrl + I)"
        />
        <FontAwesomeIcon
          size="lg"
          icon={faUnderline}
          onClick={() => editorAvailable && editor.chain().focus().toggleUnderline().run()}
          className={editorAvailable && editor.isActive("underline") ? "is-active" : ""}
          title="Underline (Ctrl + U)"
        />
      </div>

      <div className="text-align">
        <FontAwesomeIcon
          size="lg"
          icon={faAlignLeft}
          onClick={() => editorAvailable && editor.chain().focus().setTextAlign("left").run()}
          className={editorAvailable && editor.isActive({ textAlign: "left" }) ? "is-active" : ""}
          title="Align Left (Ctrl + Shift + L)"
        />
        <FontAwesomeIcon
          size="lg"
          icon={faAlignCenter}
          onClick={() => editorAvailable && editor.chain().focus().setTextAlign("center").run()}
          className={editorAvailable && editor.isActive({ textAlign: "center" }) ? "is-active" : ""}
          title="Align Center (Ctrl + Shift + E)"
        />
        <FontAwesomeIcon
          size="lg"
          icon={faAlignRight}
          onClick={() => editorAvailable && editor.chain().focus().setTextAlign("right").run()}
          className={editorAvailable && editor.isActive({ textAlign: "right" }) ? "is-active" : ""}
          title="Align Right (Ctrl + Shift + R)"
        />
        <FontAwesomeIcon
          size="lg"
          icon={faAlignJustify}
          onClick={() => editorAvailable && editor.chain().focus().setTextAlign("justify").run()}
          className={editorAvailable && editor.isActive({ textAlign: "justify" }) ? "is-active" : ""}
          title="Align Justify (Ctrl + Shift + J)"
        />
      </div>

      <div className="make-list">
        <FontAwesomeIcon
          size="lg"
          icon={faList12}
          onClick={() => editorAvailable && editor.chain().focus().toggleOrderedList().run()}
          title="Numbered List (Ctrl + Shift + 7)"
        />
        <FontAwesomeIcon
          size="lg"
          icon={faList}
          onClick={() => editorAvailable && editor.chain().focus().toggleBulletList().run()}
          title="Bullet List (Ctrl + Shift + 8)"
        />  
      </div>

      <div className="text-head-bottom">
        <BsSuperscript
          fontSize={"26px"}
          onClick={() => editorAvailable && editor.chain().focus().toggleSuperscript().run()}
          title="Superscript (Ctrl + Shift + Up Arrow)"
        />
        <BsSubscript
          fontSize={"26px"}
          onClick={() => editorAvailable && editor.chain().focus().toggleSubscript().run()}
          title="Subscript (Ctrl + Shift + Down Arrow)"
        />
      </div>

      <div className="text-head-bottom">
        <FontAwesomeIcon
          size="lg"
          onClick={() => editorAvailable && editor.chain().focus().toggleHighlight().run()}
          disabled={editorAvailable && !editor.can().chain().focus().toggleHighlight().run()}
          title="Highlight (Ctrl + Shift + H)"
          icon={faHighlighter}
        />
        <FontAwesomeIcon
          size="lg"
          onClick={() => editorAvailable && editor.chain().focus().toggleCodeBlock().run()}
          disabled={editorAvailable && !editor.can().chain().focus().toggleCodeBlock().run()}
          className={editorAvailable && editor.isActive('codeBlock') ? 'is-active' : ''}
          title="Code Block (Ctrl + E)"
          icon={faCode}
        />
      </div>

      {showFileUploadAndExplaination && <ImageUploadAndExplanation showFileUploadAndExplaination={showFileUploadAndExplaination} />}
    </div>
  );
}
