import QuizApi from "../../services/QuizApi";
import "../../assets/styles/Settings.css";
import { useLocation, useParams } from "react-router-dom";
import { MainNavbar } from "../Common/Navbar";
import React, { useEffect, useState, Component } from "react";
import Sidebar from "../Common/Sidebar";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { DateRangePicker, Stack } from "rsuite";
import $ from "jquery";
import { addDays, addHours, addMinutes, format } from "date-fns";
import { Helmet } from "react-helmet";
import Cookies from "js-cookie";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import FormatText from "../Common/FormatText";

function QuizSettings() {
  const { quizId } = useParams();
  const [settings, setSettings] = useState(null);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const { beforeToday } = DateRangePicker;
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [quizTransition, setQuizTransition] = useState("manual"); // manual or automatic
  const [quizPassFail, setQuizPassFail] = useState("pass"); // pass or any
  const [nextQuizId, setNextQuizId] = useState("");
  const customBtn = {
    ok: "Apply",
  };

  // const [finalDateRange, setFinalDateRange] = useState({ startDate: new Date(Date.parse(settings?.startdate)), endDate: addDays(new Date(), 1) })
  const [finalDateRange, setFinalDateRange] = useState({
    startDate: "",
    endDate: "",
  });
  const [dateRangePicker, setDateRangePicker] = useState([
    {
      startDate: finalDateRange?.startDate,
      endDate: finalDateRange?.endDate,
      key: "selection",
    },
  ]); // for date range picker
  const [quizTitle, setQuizTitle] = useState("");
  
  useEffect(() => {
    setDateRangePicker([
      {
        startDate: finalDateRange?.startDate,
        endDate: finalDateRange?.endDate,
        key: "selection",
      },
    ]);
  }, [finalDateRange]);

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  let userData = { user: {} };

  const handleChangeTiming = (e) => {
    const { id, value } = e.target;
    let startDate = new Date(dateRangePicker[0].startDate);
    let endDate = new Date(dateRangePicker[0].endDate);

    switch (id) {
      case "start-hrs":
        startDate.setHours(value);
        break;
      case "start-min":
        startDate.setMinutes(value);
        break;
      case "start-am-pm":
        if (value === "am") {
          let date = new Date(addHours(startDate, -12));
          startDate = date;
        } else {
          let date = new Date(addHours(startDate, 12));
          startDate = date;
        }
        break;

      case "end-hrs":
        endDate.setHours(value);
        break;
      case "end-min":
        endDate.setMinutes(value);
        break;
      case "end-am-pm":
        if (value === "am") {
          let date = new Date(addHours(endDate, -12));
          endDate = date;
        } else {
          let date = new Date(addHours(endDate, 12));
          endDate = date;
        }
        break;
      default:
        break;
    }
    setDateRangePicker([
      {
        startDate: startDate,
        endDate: endDate,
        key: "selection",
      },
    ]);
  };

  const handleDateTimeInputSave = () => {
    setFinalDateRange({
      startDate: dateRangePicker[0].startDate,
      endDate: dateRangePicker[0].endDate,
    });
    setIsDatePickerOpen(false);

    const formattedStartDate = new Date(dateRangePicker[0].startDate); //.toISOString();
    const formattedEndDate = new Date(dateRangePicker[0].endDate); //.toISOString();
    const dataToUpdate = {
      startdate: formattedStartDate,
      enddate: formattedEndDate,
    };

    QuizApi.patch(`/generalsettings/${quizId}/`, dataToUpdate)
      .then((response) => {
        setIsDatePickerOpen(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const encodedData = Cookies.get("authToken");
  if (encodedData) {
    const decodedData = decodeURIComponent(encodedData);
    userData = JSON.parse(decodedData);
  }

  const location = useLocation();
  const [allQuizzes, setAllQuizzes] = useState([]);

  const links = [
    { to: `/general-settings/${quizId}`, text: "General Settings" },
    { to: `/scoring-option/${quizId}`, text: "Scoring Options" },
    { to: `/quiz-tracking/${quizId}`, text: "Quiz Tracking" },
    { to: `/security-settings/${quizId}`, text: "Browser Restrictions" },
    {
      to: `/smart-proctoring-system/${quizId}`,
      text: "Proctoring and Security",
    },
    { to: `/lead-integration/${quizId}`, text: "Lead Integration" },
  ];

  useEffect(() => {
    try {
      QuizApi.get(`/generalsettings/${quizId}/`).then((response) => {
        setSettings(response.data);
        const allQuizzesTemp = response?.data.quizzes_list.filter(
          (quiz) => quiz.id !== parseInt(quizId),
        );
        setAllQuizzes(allQuizzesTemp);
        if (!Boolean(response?.data?.next_quiz)) {
          setNextQuizId(allQuizzesTemp[0]?.id);
          handleNextQuizChange("next_quiz", allQuizzesTemp[0]?.id);
        } else {
          setNextQuizId(response?.data?.next_quiz);
        }
        setQuizTitle(response?.data?.quiz_title);
        setQuizTransition(response?.data?.transition);
        setQuizPassFail(response?.data?.progress_option);
        setFinalDateRange({
          startDate: response?.data?.startdate ? new Date(response?.data?.startdate) : new Date(),
          endDate: response?.data?.enddate ? new Date(response?.data?.enddate) : addMinutes(new Date(), response?.data?.minutes || 0),
        });
        setDateRangePicker([
          {
            startDate: response?.data?.startdate ? new Date(response?.data?.startdate) : new Date(),
            endDate: response?.data?.enddate ? new Date(response?.data?.enddate) : addMinutes(new Date(), response?.data?.minutes || 0),
            key: "selection",
          },
        ]);
      });
    } catch (error) {
      console.error("Error getting quiz settings:", error);
    }
  }, [quizId]);

  function addMonthLabels() {
    $(".rs-calendar-header-month-toolbar").each(function () {
      $(this).before("<div class='month-label'>Quiz Start Time</div>");
      $(this).before("<div class='Date-label'>Select Date</div>");
      $(this).before("<div class='Time-label'>Select Time</div>");
    });
    const monthLabels = $(".month-label");
    if (monthLabels.length > 1) {
      monthLabels.eq(1).html("Quiz End Time");
    }
    const daterangeHeader = $(".rs-picker-daterange-header");
    daterangeHeader.before("<div class='Setto_label'>Change to: &nbsp;</div>");
  }

  const togglePopup = () => {
    setPopupVisible(!isPopupVisible);
  };

  const handleDateInputChange = (selectedDateRange) => {
    if (selectedDateRange) {
      const [start, end] = selectedDateRange;
      setStartDate(start);
      setEndDate(end);
      saveDatesToDatabase(start, end);
    }
  };

  const saveDatesToDatabase = (startDate, endDate) => {
    const formattedStartDate = new Date(startDate).toISOString();
    const formattedEndDate = new Date(endDate).toISOString();
    const dataToUpdate = {
      startdate: formattedStartDate,
      enddate: formattedEndDate,
    };

    QuizApi.patch(`/generalsettings/${quizId}/`, dataToUpdate)
      .then((response) => {
        console.log(" ");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleNextQuizChange = (key, value) => {
    QuizApi.patch(`/generalsettings/${quizId}/`, { [key]: value })
      .then((response) => { })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleSelectChange = (event, key) => {
    let { value, type, checked } = event.target;
    if (value < -1) {
      value = 1;
    }
    if (type === "checkbox") {
      if (key === "revealanswers") {
        setSettings({
          ...settings,
          [key]: checked ? 1 : 0,
          allowbackbutton: 0,
        });
      } else {
        setSettings({
          ...settings,
          [key]: checked ? 1 : 0,
        });
      }
    } else {
      setSettings({
        ...settings,
        [key]: value,
      });
    }

    if (key === "quizaavailability") {
      if (value === "always") {
        setFinalDateRange({
          startDate: "null",
          endDate: null,
        });
        saveDatesToDatabase(null, null);
      } else if (value === "customschedule") {
        const now = new Date();
        const endDate = addMinutes(now, settings?.minutes || 0);
        setFinalDateRange({
          startDate: now,
          endDate: endDate,
        });
        setDateRangePicker([
          {
            startDate: now,
            endDate: endDate,
            key: "selection",
          },
        ]);
        saveDatesToDatabase(now, endDate);
      }
    }

    QuizApi.patch(`/generalsettings/${quizId}/`, {
      [key]: type === "checkbox" ? (checked ? 1 : 0) : value,
    })
      .then((response) => {
        console.log(` `);
      })
      .catch((error) => {
        console.error(`Error updating ${key} in the backend: ${error}`);
      });
  };

  $("input").attr("autocomplete", "off");

  return (
    <div>
      {settings ? (
        <div>
          <Helmet>
            <link
              type="text/css"
              rel="stylesheet"
              href="https://cdn.jsdelivr.net/npm/rsuite@5.46.0/dist/rsuite.min.css"
            />
          </Helmet>
          <MainNavbar />
          <Sidebar quiztitle={quizTitle} links={links} />
          <div className="containermain quizsettings">
            <h1 className="main_heading">Quiz General Settings</h1>
            <div className="settingsheadings">
              Questions and Answers Settings
            </div>

            <div className="settingsrow">
              <div className="settingsrowselement">
                <p>Choose how you want to display the questions</p>
              </div>
              <select
                name="questiondisplay"
                id="questiondisplay"
                onChange={(e) => handleSelectChange(e, "questiondisplay")}
                value={settings.questiondisplay}
              >
                <option value="show one question per page">
                  Show one question per page
                </option>
                <option value="show all questions on a single page">
                  Show all questions on a single page
                </option>
              </select>
            </div>

            <div className="settingsrow">
              <div className="settingsrowselement">
                <p>Randomize Questions and Answers</p>
                Enable shuffling to deliver questions in a different<br></br>{" "}
                order to each quiz taker.
              </div>
              <select
                name="randomorder"
                id="selectrandomorder"
                onChange={(e) => handleSelectChange(e, "randomorder")}
                value={settings.randomorder}
              >
                <option value="Do not shuffle" defaultValue="" hidden>
                  {settings.randomorder}
                </option>
                <option value="Do not shuffle">Do not shuffle</option>
                <option value="shuffle questions">Shuffle Questions</option>
                <option value="shuffle answers">Shuffle Answers</option>
                <option value="shuffle questions and answers">
                  Shuffle Questions and Answers
                </option>
              </select>
            </div>
            <div className="settingsrow" style={{ display: "flex" }}>
              <div className="settingsrowselement" style={{ display: "block" }}>
                <p>Choose the Number of Questions to display </p>
              </div>
              <div className="ques_displayed">
                <select
                  name="selectnumberofquestion"
                  id="selectnumberofquestions"
                  onChange={(e) =>
                    handleSelectChange(e, "selectnumberofquestion")
                  }
                  value={settings.selectnumberofquestion}
                >
                  <option value="select all questions" defaultValue="">
                    Select all questions
                  </option>
                  <option value="select by number">
                    Randomly display X number of Questions
                  </option>
                </select>

                {settings.selectnumberofquestion === "select by number" && (
                  <div id="numberofquestiosdiv">
                    X&nbsp;=&nbsp;
                    <input
                      style={{
                        height: "25px",
                        fontSize: "15px",
                        fontWeight: "500",
                        textAlign: "center",
                      }}
                      type="number"
                      name="1"
                      onChange={(e) => {
                        setSettings({
                          ...settings,
                          numberofquestions: e.target.value,
                        });
                        handleSelectChange(e, "numberofquestions");
                      }}
                      onBlur={(e) => {
                        if (e.target.value >= settings.total_questions) {
                          e.target.value = settings.total_questions;
                        } else if (e.target.value <= 0) {
                          e.target.value = 1;
                        }
                        handleSelectChange(e, "numberofquestions");
                      }}
                      // onKeyUp={(e) => {
                      //   if (e.target.value >= settings.total_questions) {
                      //     e.target.value = settings.total_questions;
                      //   } else if (e.target.value <= 0) {
                      //     e.target.value = 1;
                      //   }
                      //   handleSelectChange(e, "numberofquestions")
                      // }
                      // }
                      value={settings.numberofquestions}
                      min="1"
                      max={settings.total_questions}
                      id="selectbynumber"
                    ></input>
                  </div>
                )}
                <font id="questioncount">
                  {settings.selectnumberofquestion === "select by number" ? (
                    <span id="que_number">
                      {settings.numberofquestions} Question will be displayed
                    </span>
                  ) : (
                    <span className="singlenumber">
                      {settings.total_questions} Question will be displayed
                    </span>
                  )}
                </font>
              </div>
            </div>
            <div className="settingsrow">
              <div className="settingsrowselement">
                <p>Maximum attempts allowed to complete Quiz </p>
                Set maximum attempts a person can take to complete the quiz.
              </div>
              <select
                name="numberofattempts"
                id="selectnumberofattempts"
                onChange={(e) => handleSelectChange(e, "numberofattempts")}
                value={settings.numberofattempts}
              >
                <option value="unlimited attempts" defaultValue="">
                  Unlimited Attempts
                </option>
                <option value="1 attempts">1 Attempt</option>
                <option value="2 attempts">2 Attempts</option>
                <option value="3 attempts">3 Attempts</option>
                <option value="4 attempts">4 Attempts</option>
                <option value="5 attempts">5 Attempts</option>
                <option value="10 attempts">10 Attempts</option>
              </select>
            </div>

            {settings.questiondisplay === "show one question per page" && (
              <div id="showhiderevealbackbtn">
                <div className="settingsrow">
                  <div className="settingsrowselement">
                    <p>Reveal Answers during Quiz</p>
                  </div>
                  <input
                    type="checkbox"
                    className="enterkeyevent"
                    name="revealanswers"
                    id="revealanswers"
                    onChange={(e) => handleSelectChange(e, "revealanswers")}
                    checked={settings.revealanswers}
                  ></input>
                </div>
                <div className="settingsrow">
                  <div className="settingsrowselement">
                    <p>Allow Back Button on Quiz</p>
                    Show a back button to go to the previous question.
                  </div>
                  <input
                    type="checkbox"
                    name="allowbackbutton"
                    id="allowbackbutton"
                    checked={settings.allowbackbutton}
                    onChange={(e) => handleSelectChange(e, "allowbackbutton")}
                    disabled={settings.revealanswers}
                    readOnly={settings.revealanswers}
                  ></input>
                </div>
              </div>
            )}
            <div className="settingsrow">
              <div className="settingsrowselement">
                <p>Show number of questions</p>
                Display total number of questions before starting the quiz.
              </div>
              <input
                type="checkbox"
                className="enterkeyevent"
                name="shownumberofquestions"
                id="shownumberofquestions"
                onChange={(e) => handleSelectChange(e, "shownumberofquestions")}
                checked={settings.shownumberofquestions}
              ></input>
            </div>
            <div className="settingsheadings">Quiz Time Settings</div>
            <div className="settingsrow" style={{ display: "flex" }}>
              <div className="settingsrowselement">
                <p>Quiz Time Limit</p>
                Set a time limit for quiz takers to complete the quiz.
              </div>
              <select
                name="timelimit"
                id="selecttimelimit"
                onChange={(e) => handleSelectChange(e, "timelimit")}
                value={settings.timelimit}
                style={{ width: "19.5%" }}
              >
                <option value="set time limit" defaultValue="">
                  Set Time Limit
                </option>
                <option value="no time limit">No Time Limit</option>
              </select>
              &nbsp;&nbsp;
              {settings.timelimit === "set time limit" && (
                <div id="limitminutes">
                  <input
                    style={{ width: "80px", height: "25px" }}
                    type="number"
                    name="minutes"
                    id="minutes"
                    onChange={(e) => handleSelectChange(e, "minutes")}
                    onBlur={(e) => handleSelectChange(e, "minutes")}
                    onKeyUp={(e) => handleSelectChange(e, "minutes")}
                    value={settings.minutes}
                  ></input>
                  &nbsp;&nbsp;Minutes
                </div>
              )}
            </div>
            <div className="settingsrow" id="displaycountdown">
              <div className="settingsrowselement">
                <p>Display Countdown Timer when Taking Quiz</p>
              </div>
              <input
                type="checkbox"
                className="enterkeyevent"
                name="displaycountdown"
                id="displaycountdown"
                checked={settings.displaycountdown}
                onChange={(e) => handleSelectChange(e, "displaycountdown")}
              ></input>
            </div>
            <div className="settingsrow">
              <div className="settingsrowselement">
                <p>Quiz Availability</p>
                Set quiz as always available or only during a<br></br> Certain
                date and time period.
              </div>
              <div className="settingsrowselement">
                <div
                  style={{ display: "flex", gap: "10px", marginBottom: "10px" }}
                >
                  <select
                    style={{ maxWidth: "350px" }}
                    id="quizaavailability"
                    name="quizaavailability"
                    onChange={(e) => handleSelectChange(e, "quizaavailability")}
                    value={settings.quizaavailability}
                  >
                    <option value="always" defaultValue="">
                      Always
                    </option>
                    <option value="customschedule">Custom Schedule </option>
                  </select>
                  {settings.quizaavailability === "customschedule" && (
                    <button
                      id="customizemessage"
                      className="btn btn-primary"
                      onClick={togglePopup}
                    >
                      Customize Expired Message
                    </button>
                  )}
                </div>
                {isPopupVisible && (
                  <div className="popupoverlay">
                    <div className="popup">
                      <div className="popup-content">
                        <div className="popup-body">
                          <a className="close" onClick={togglePopup}></a>
                          <h3 style={{ fontWeight: "600" }}>
                            Enter message when quiz is not available.
                          </h3>
                          <br></br>
                          <div className="customizedheadingsection">
                            <div>
                              <p>Message to display before start time:</p>
                              <textarea
                                style={{ resize: "none" }}
                                name="msgbeforequiz"
                                id="msgbeforequiz"
                                cols="30"
                                rows="5"
                                onChange={(e) =>
                                  handleSelectChange(e, "msgbeforequiz")
                                }
                                onBlur={(e) =>
                                  handleSelectChange(e, "msgbeforequiz")
                                }
                                onKeyUp={(e) =>
                                  handleSelectChange(e, "msgbeforequiz")
                                }
                                value={settings.msgbeforequiz}
                              ></textarea>
                            </div>
                            <br></br>
                            <div>
                              <p>Message to display after end time:</p>
                              <textarea
                                style={{ resize: "none" }}
                                name="msgafterquiz"
                                id="msgafterquiz"
                                cols="30"
                                rows="5"
                                onChange={(e) =>
                                  handleSelectChange(e, "msgafterquiz")
                                }
                                onBlur={(e) =>
                                  handleSelectChange(e, "msgafterquiz")
                                }
                                onKeyUp={(e) =>
                                  handleSelectChange(e, "msgafterquiz")
                                }
                                value={settings.msgafterquiz}
                              ></textarea>
                            </div>
                          </div>
                          <br></br>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <font color="grey">
                              You may use tags for dynamic data
                              &lt;start-time&gt;,&lt;end-time&gt;
                            </font>
                            <button
                              className="savebtnquiz"
                              onClick={togglePopup}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {settings.quizaavailability === "customschedule" && (
                  <div className="timeavailabality" id="timeavailabality">
                    <div className="timeavailabalityhead">
                      <span>Select the Quiz Start and End Times:</span>
                      <input
                        readOnly={true}
                        id="pickadateandtime"
                        value={`${format(new Date(finalDateRange.startDate), "yyyy MMM, dd hh:mm aa")} - ${format(new Date(finalDateRange.endDate), "yyyy MMM, dd hh:mm aa")}`}
                        onClick={() => {
                          setIsDatePickerOpen(!isDatePickerOpen);
                        }}
                      />
                      {isDatePickerOpen && (
                        <div className="date-time-picker">
                          <DateRangePicker
                            onChange={(item) =>
                              setDateRangePicker([item.selection])
                            }
                            showSelectionPreview={false}
                            moveRangeOnFirstSelection={false}
                            months={2}
                            ranges={dateRangePicker}
                            direction="horizontal"
                            color="black"
                            // rangeColors={["#ebf4f8",]}
                            showMonthAndYearPickers={false}
                            // showDateDisplay={false}
                            showPreview={true}
                            minDate={new Date()}
                            staticRanges={[]}
                            inputRanges={[]}
                            showDateDisplay={false}
                            fixedHeight={true}
                          />
                          <div className="time-picker">
                            <div className="start-time">
                              <span className="heading">Quiz Start Time</span>
                              <div className="head">
                                <span>hr</span>
                                <span>min</span>
                              </div>
                              <div className="body">
                                <select
                                  className="select-hr"
                                  value={
                                    dateRangePicker[0].startDate.getHours() %
                                    12 || 12
                                  }
                                  onChange={handleChangeTiming}
                                  id="start-hrs"
                                >
                                  {Array.from(Array(12).keys()).map(
                                    (item, index) => (
                                      <option key={index} value={item + 1}>
                                        {item + 1}
                                      </option>
                                    ),
                                  )}
                                </select>
                                <select
                                  className="select-min"
                                  id="start-min"
                                  onChange={handleChangeTiming}
                                  value={dateRangePicker[0].startDate.getMinutes()}
                                >
                                  {Array.from(Array(60).keys()).map(
                                    (item, index) => (
                                      <option key={index} value={item}>
                                        {item}
                                      </option>
                                    ),
                                  )}
                                </select>

                                <select
                                  className="select-am-pm"
                                  value={
                                    dateRangePicker[0].startDate.getHours() >=
                                      12
                                      ? "pm"
                                      : "am"
                                  }
                                  id="start-am-pm"
                                  onChange={handleChangeTiming}
                                >
                                  <option value="am">AM</option>
                                  <option value="pm">PM</option>
                                </select>
                              </div>
                            </div>
                            <div className="end-time">
                              <span className="heading">Quiz End Time</span>
                              <div className="head">
                                <span>hr</span>
                                <span>min</span>
                              </div>
                              <div className="body">
                                <select
                                  className="select-hr"
                                  id="end-hrs"
                                  value={
                                    dateRangePicker[0].endDate.getHours() %
                                    12 || 12
                                  }
                                  onChange={handleChangeTiming}
                                >
                                  {Array.from(Array(12).keys()).map(
                                    (item, index) => (
                                      <option key={index} value={item + 1}>
                                        {item + 1}
                                      </option>
                                    ),
                                  )}
                                </select>
                                <select
                                  className="select-min"
                                  value={dateRangePicker[0].endDate.getMinutes()}
                                  id="end-min"
                                  onChange={handleChangeTiming}
                                >
                                  {Array.from(Array(60).keys()).map(
                                    (item, index) => (
                                      <option key={index} value={item}>
                                        {item}
                                      </option>
                                    ),
                                  )}
                                </select>

                                <select
                                  className="select-am-pm"
                                  value={
                                    dateRangePicker[0].endDate.getHours() >= 12
                                      ? "pm"
                                      : "am"
                                  }
                                  id="end-am-pm"
                                  onChange={handleChangeTiming}
                                >
                                  <option value="am">AM</option>
                                  <option value="pm">PM</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="final-date-time">
                            <span>
                              {`${format(new Date(dateRangePicker[0].startDate), "yyyy MMM, dd hh:mm aa")} - ${format(new Date(dateRangePicker[0].endDate), "yyyy MMM, dd hh:mm aa")}`}
                            </span>
                            <div>
                              <button
                                className="cancel-btn"
                                onClick={() => {
                                  setIsDatePickerOpen(false);
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                className="apply-btn"
                                onClick={handleDateTimeInputSave}
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                      {/*                       
                      <DateRangePicker
                        onOpen={addMonthLabels}
                        format="dd MMM, yyyy  hh:mm aa"
                        Placement="auto"
                        autoComplete="off"
                        locale={customBtn}
                        placeholder={`${format(
                          new Date(settings.startdate),
                          "dd MMM yyyy hh:mm aa"
                        )} - ${format(
                          new Date(settings.enddate),
                          "dd MMM yyyy hh:mm aa"
                        )}`}
                        showMeridian
                        id="datetimes"
                        shouldDisableDate={beforeToday()}
                        ranges={[]}
                        onOk={(e) => {
                          handleDateInputChange(e);
                        }}
                        onChange={(e) => {
                          handleDateInputChange(e);
                        }}
                        onClose={(e) => {
                          handleDateInputChange(e);
                        }}
                        size="lg"
                        defaultCalendarValue={[
                          new Date(settings.startdate),
                          new Date(settings.enddate),
                        ]}
                      /> */}
                    </div>
                  </div>
                )}
                <div className="timeavailabality">
                  <div
                    className="timeavailabalityhead"
                    style={{ fontWeight: "100", fontStyle: "Italic" }}
                  >
                    <b style={{ fontWeight: "400", fontStyle: "Italic" }}>
                      Your Time Zone:<br></br>
                      <font id="timezonetext">
                        GMT+05:30 (India Standard Time)
                      </font>{" "}
                      <a href="">(Change This)</a>
                    </b>
                  </div>
                </div>
              </div>
            </div>
            <div className="settingsheadings">Progress to the Next Quiz</div>
            <div className="settingsrow" id="saveandresume">
              <div className="settingsrowselement">
                <p>Give quiz taker options to progress to next quiz</p>
              </div>
              <input
                type="checkbox"
                name="show_next_quiz"
                id="show_next_quiz"
                onChange={(e) => handleSelectChange(e, "show_next_quiz")}
                checked={settings.show_next_quiz}
              ></input>
            </div>

            {settings.show_next_quiz ? (
              <>
                <div className="settingsrow">
                  <div
                    className="settingsrowselement"
                    id="regradless-pass-fail"
                  >
                    Progress to next quiz, regardless of pass/fail.
                  </div>
                  <input
                    type="radio"
                    htmlFor="regradless-pass-fail"
                    value="any"
                    name="next-pass-fail"
                    checked={quizPassFail === "any" && true}
                    onChange={() => {
                      setQuizPassFail("any");
                      handleNextQuizChange("progress_option", "any");
                    }}
                  />
                </div>

                <div className="settingsrow">
                  <div className="settingsrowselement" id="only-pass">
                    Progress to next quiz only upon passing current one.
                  </div>
                  <input
                    type="radio"
                    htmlFor="only-pass"
                    name="next-pass-fail"
                    value="pass"
                    onChange={() => {
                      setQuizPassFail("pass");
                      handleNextQuizChange("progress_option", "pass");
                    }}
                    checked={quizPassFail === "pass" && true}
                  />
                </div>

                <div className="settingsrow">
                  <div className="settingsrowselement">
                    <p>How to Transition to the Next Quiz</p>
                  </div>
                </div>

                <div className="settingsrow">
                  <div className="settingsrowselement" name="automatic">
                    <p>Automatic transition</p>
                    Give the next quiz immediately without prompting.
                  </div>
                  <input
                    type="radio"
                    htmlFor="automatic"
                    name="transition"
                    checked={quizTransition === "automatic" && true}
                    onChange={() => {
                      setQuizTransition("automatic");
                      handleNextQuizChange("transition", "automatic");
                    }}
                  />
                </div>

                <div className="settingsrow">
                  <div className="settingsrowselement" name="manual">
                    <p>Manual transition</p>
                    Display current quiz completion information and next quiz
                    start button.
                  </div>
                  <input
                    type="radio"
                    htmlFor="manual"
                    name="transition"
                    checked={quizTransition === "manual" && true}
                    onChange={() => {
                      setQuizTransition("manual");
                      handleNextQuizChange("transition", "manual");
                    }}
                  />
                </div>
                <br />
                <table className="next-quiz-table">
                  <thead>
                    <tr>
                      <th>Select</th>
                      <th>Quiz name</th>
                      <th>Total question</th>
                      <th>Time limit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allQuizzes.map((quiz, index) => (
                      <tr key={index} htmlFor={`key_${index}`}>
                        <td className="text-center">
                          <label htmlFor={`key_${index}`}>
                            <input
                              type="radio"
                              name="next-quiz"
                              id={`key_${index}`}
                              onChange={() => {
                                setNextQuizId(quiz.id);
                                handleNextQuizChange("next_quiz", quiz.id);
                              }}
                              value={quiz.id}
                              checked={nextQuizId === quiz.id && true}
                            />
                          </label>
                        </td>
                    
                        <FormatText text={`<label for=key_${index}> ${quiz.quiztitle} </label>`} />
                        <td className="text-center">
                          <label htmlFor={`key_${index}`}>
                            {quiz.total_questions}
                          </label>
                        </td>
                        <td className="text-center">
                          <label htmlFor={`key_${index}`}>
                            {quiz.time_limit}
                          </label>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              ""
            )}
            <p className="settingsheadings">&nbsp; Video Questions Settings:</p>
            <div className="settingsrow">
              <div className="settingsrowselement">
                <p>Maximum video recording retry attempts</p>
              </div>
              <select
                name="recordingretryattempts"
                id="selectretryattempts"
                onChange={(e) =>
                  handleSelectChange(e, "recordingretryattempts")
                }
                value={settings.recordingretryattempts}
              >
                <option value="1 time">1 Time</option>
                <option value="2 times" defaultValue="">
                  2 Times
                </option>
                <option value="3 times">3 Times</option>
                <option value="4 times">4 Times</option>
                <option value="5 times">5 Times</option>
                <option value="6 times">6 Times</option>
                <option value="7 times">7 Times</option>
                <option value="8 times">8 Times</option>
                <option value="9 times">9 Times</option>
                <option value="unlimited times">Unlimited Times</option>
              </select>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
            </div>
          </div>
        </div>
      ) : (
        <p>Loading settings...</p>
      )}
    </div>
  );
}
export default QuizSettings;
