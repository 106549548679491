import React, { useState } from "react";
import "../../../assets/styles/components/essay.css";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../state";
import {
  handleDeleteQuestion,
  handleQuestionImageUpload,
  handleQuestionSubmit,
} from "../../../utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { getHTMLtoText } from "../../../utils/generalHelp";
import TextEditor from "../../Common/TextEditor";

export default function Essay(props) {
  const [propsValues, setPropsValues] = useState({ ...props.data });
  const [wordLimit, setWordLimit] = useState(props.data.options.max_word);
  const isExplanationOn = useSelector((state) => state.isExplanationOn);
  const dispatch = useDispatch();
  const { setImagePath } = bindActionCreators(actionCreators, dispatch);
  const setQuestions = useSelector((state) => state.setQuestions);
  const imagePath = useSelector((state) => state.imagePath);

  const [plusWord, setPlusWord] = useState("Target");
  const [minusWord, setMinusWord] = useState("Explain");

  const [plusPoint, setPlusPoint] = useState(10);
  const [minusPoint, setMinusPoint] = useState(-5);

  const [maxScore, setMaxScore] = useState(20);
  const [minScore, setMinScore] = useState(-10);

  const [isOnAutoGrading, setIsOnAutoGrading] = useState(false);

  const handleWordLimitBlur = (event) => {
    let val = event.target.value;
    if (val <= 0) {
      val = 1;
      setWordLimit(1);
    }
    handleQuestionSubmit(setQuestions, {
      options: {
        description: propsValues.options.description,
        max_word: val,
      },
    });
  };

  const handleWordLimitChange = (event) => {
    const val = event.target.value;
    if (val < 1000) {
      setWordLimit(val);
    }
    if (val < 10) {
      setWordLimit(val);
    }
  };

  const handleQuestionTitleChange = (currentContent) => {
    try {
      let element = document.getElementById(`question_id-${setQuestions}`);

      const tempEle = document.createElement('div')

      tempEle.innerHTML = currentContent;

      element.innerText = tempEle.innerText
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="question-main-container">
        <div className="question-title">
          <div className="question-title-container">
            <div className="question-title-head">
              Essay Question
              <span className="total-questions-of">1/10</span>
            </div>
            <TextEditor placeholder={"Write your question here..."} setEditor={props.setEditor} initialContent={propsValues.question_title} onContentChange={(currentContent) => {
              setPropsValues((prev) => {
                handleQuestionSubmit(setQuestions, {
                  question_title: currentContent,
                });
                handleQuestionTitleChange(currentContent);
                return {
                  ...prev,
                  question_title: currentContent,
                }
              });
            }} />
          </div>
          <FontAwesomeIcon
            icon={faTrashCan}
            className="question-delete-icon"
            size="xl"
            onClick={() => {
              handleDeleteQuestion(setQuestions);
            }}
          />
        </div>
        {getHTMLtoText(propsValues["question_title"]) === "" && (
          <p className="question-title-required">
            Please enter a title for your question to proceed
          </p>
        )}
        {imagePath && (
          <div className="upload-img">
            <div className="upload-img-div">
              <button
                className="btn-remove-img"
                onClick={() => {
                  handleQuestionImageUpload({
                    image: "",
                    question_id: setQuestions,
                  });
                }}
              >
                Remove
              </button>
              <img className="question-image" src={imagePath} alt="" />
            </div>
          </div>
        )}
        <div className="question-choices">
          <div className="question-choices-head">
            Set Description (optional)
            <span className="float-me-right">
              Set limit to:{" "}
              <input
                className="wordLimit"
                pattern="[0-9]+"
                type="number"
                name="word"
                value={wordLimit}
                max={999}
                min={1}
                onChange={handleWordLimitChange}
                onBlur={handleWordLimitBlur}
              />{" "}
              words
            </span>
          </div>
          <div className="question-choices-body">
            <div className="choice">
              <TextEditor minHeight={"100px"} placeholder={`Add description..`} setEditor={props.setEditor} initialContent={propsValues["options"]["description"]} onContentChange={(currentContent) => {
                handleQuestionSubmit(setQuestions, {
                  options: {
                    description: currentContent,
                  },
                });
                setPropsValues({
                  ...propsValues,
                  options: { ...propsValues["options"], description: currentContent },
                });
              }} />
            </div>
          </div>
        </div>
        {isExplanationOn && (
          <div className="explanation">
            <div className="explanation-head">
              Add Explanation for Answer (Optional)
            </div>
            <TextEditor placeholder={`Type Explanation here...`} setEditor={props.setEditor} initialContent={propsValues["explanation_text"]} onContentChange={(currentContent) => {
              handleQuestionSubmit(setQuestions, {
                explanation_text: currentContent,
              });
              setPropsValues({
                ...propsValues,
                explanation_text: currentContent,
              });
            }} />

          </div>
        )}
        <div className="essay-auto-grading">
          <div className="explanation-head auto-checkbox">
            <input
              type="checkbox"
              className="auto-check"
              checked={isOnAutoGrading}
              onChange={() => {
                setIsOnAutoGrading(!isOnAutoGrading);
              }}
            />{" "}
            Enable auto grading using keyword
          </div>
          <div className="explanation-body">
            <div className="prem-feature">(Premium Feature)</div>
            <div className="explain-essay-grading">
              Enabling auto-grade allows us to automatically award provisional
              grades for your essay questions. The provisional grade is
              calculated based on the target phrases or keywords present in the
              essay response. At any time, you may override the automatically
              generated grade by giving a standard manual grade.
            </div>
            <div className="grading-get">
              {isOnAutoGrading && (
                <>
                  <div className="explanation-head">
                    Add your keyword along with the ± point value
                    <div className="plus-point">
                      <input
                        type="text"
                        value={plusWord}
                        onChange={(event) => {
                          setPlusWord(event.target.value);
                        }}
                      />
                      <input
                        type="number"
                        value={plusPoint}
                        onChange={(event) => {
                          setPlusPoint(event.target.value);
                        }}
                      />
                      Points
                    </div>
                    <div className="minus-point">
                      <input
                        type="text"
                        value={minusWord}
                        onChange={(event) => {
                          setMinusWord(event.target.value);
                        }}
                      />
                      <input
                        type="number"
                        value={minusPoint}
                        onChange={(event) => {
                          setMinusPoint(event.target.value);
                        }}
                      />
                      Points
                    </div>
                    <span className="add-more-btn">
                      +Add More Words or Phrases
                    </span>
                  </div>
                  <div className="max-min-score-set">
                    <span className="max-score">
                      Maximum score for this question is{" "}
                      <input
                        type="number"
                        value={maxScore}
                        onChange={(event) => {
                          setMaxScore(event.target.value);
                        }}
                      />{" "}
                      Points
                    </span>
                    <div className="min-score">
                      Maximum negative score for this question is{" "}
                      <input
                        type="number"
                        value={minScore}
                        onChange={(event) => {
                          setMinScore(event.target.value);
                        }}
                      />{" "}
                      Points{" "}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
