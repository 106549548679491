import { faFile, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import "../../../assets/styles/components/addDocument.css";
import SelectFileUploadType from "../SelectFileUploadType";
import { useSelector } from "react-redux";
import {
  handleDeleteQuestion,
  handleQuestionSubmit,
} from "../../../utils/helpers";
import { getHTMLtoText } from "../../../utils/generalHelp";
import TextEditor from "../../Common/TextEditor";

export default function AddDocument(props) {
  const [propsValues, setPropsValues] = useState({ ...props.data });
  const setQuestions = useSelector((state) => state.setQuestions);
  const [addedFileFormat, setAddedFileFormat] = useState(
    props.data.options.available_file_type || [],
  );
  const isExplanationOn = useSelector((state) => state.isExplanationOn);

  const removeExtraCss = (element) => {
    let elementTree = element.getElementsByTagName("*");
    let res = "";
    const needed_css_rules = ["fontStyle", "fontWight"];

    for (let i = 0; i < elementTree.length; i++) {
      let ele = elementTree[i];
      if (ele.tagName === "INPUT") {
        continue;
      }
      let style_need = {};
      Object.entries(ele.style).map(([key, value]) => {
        if (needed_css_rules.includes(key)) {
          style_need[key] = value;
        }
        return null;
      });
      ele.removeAttribute("style");
      ele.removeAttribute("class");
      Object.entries(style_need).map(([key, value]) => {
        ele.style[key] = value;
        return null;
      });
      res = res + ele;
    }

    return res;
  };

  const handleQuestionTitleChange = (currentContent) => {
    try {
      let element = document.getElementById(`question_id-${setQuestions}`);

      const tempEle = document.createElement('div')

      tempEle.innerHTML = currentContent;

      element.innerText = tempEle.innerText
    } catch (error) {
      console.log(error);
    } 
  };


  return (
    <>
      <div className="upload-question-top-main">
        <div className="question-main-container add-doc-question">
          <div className="question-title">
            <div className="question-title-container">
              <div className="question-title-head">
                Upload File
                <span className="total-questions-of">1/10</span>
              </div>
              <TextEditor placeholder={"Write your question here..."} setEditor={props.setEditor} initialContent={propsValues.question_title} onContentChange={(currentContent) => {
                setPropsValues((prev) => {
                  handleQuestionSubmit(setQuestions, {
                    question_title: currentContent,
                  });
                  handleQuestionTitleChange(currentContent);
                  return {
                    ...prev,
                    question_title: currentContent,
                  }
                });
              }} />
            </div>

            <FontAwesomeIcon
              icon={faTrashCan}
              className="question-delete-icon"
              size="xl"
              onClick={() => {
                handleDeleteQuestion(setQuestions);
              }}
            />
          </div>
          {getHTMLtoText(propsValues["question_title"]) === "" && (
            <p className="question-title-required">
              Please enter a title for your question to proceed
            </p>
          )}
          <div className="upload-img">
            <label className="btn-with-img" id="file-upload">
              <FontAwesomeIcon icon={faFile} size="xl" />
              Upload File
              {/* <input type="file" className="type-file-upload" id="file-upload" /> */}
            </label>
            <span className="file-size-limit"> File size limit: 100 MB</span>
          </div>

          <TextEditor placeholder={`Add description..`} setEditor={props.setEditor} initialContent={propsValues["options"]["description"]} onContentChange={(currentContent) => {
            handleQuestionSubmit(setQuestions, {
              options: {
                description: currentContent,
              },
            });
            setPropsValues({
              ...propsValues,
              options: { ...propsValues["options"], description: currentContent },
            });
          }} />

          {isExplanationOn && (
            <div className="explanation">
              <div className="explanation-head">
                Add Explanation for Answer (Optional)
              </div>
              <TextEditor  minHeight={"100px"} placeholder={`Type Explanation here...`} setEditor={props.setEditor} initialContent={propsValues["explanation_text"]} onContentChange={(currentContent) => {
                handleQuestionSubmit(setQuestions, {
                  explanation_text: currentContent,
                });
                setPropsValues({
                  ...propsValues,
                  explanation_text: currentContent,
                });
              }} />
            </div>
          )}
        </div>
        <SelectFileUploadType
          addedFileFormat={addedFileFormat}
          setAddedFileFormat={setAddedFileFormat}
          description={propsValues.options.description}
        />
      </div>
    </>
  );
}
