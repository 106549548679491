import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGripVertical,
  faPlus,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import QuizApi from "../../services/QuizApi";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state/index";
import "../../assets/styles/sidebar.css";
import AddQuestionPopup from "../editQuiz/AddQuestionPopup";
import { useParams } from "react-router-dom";
import NoQuestions from "../editQuiz/questionTypes/NoQuestions";
import EditQuiz from "../editQuiz/EditQuiz";
import convertHtmlToText from "../../utils/convertHtmlToText";

function decodeHtmlEntities(text) {
  const element = document.createElement("div");
  if (text) {
    element.innerHTML = text;
    return element.textContent;
  }
  return text;
}

function EditQuestionPageSidebar(props) {
  const firstQuestionId = useSelector((state) => state.firstQuestionId);
  const dispatch = useDispatch();
  const { quizQuestionId, setNavbarActiveTab } = bindActionCreators(
    actionCreators,
    dispatch,
  );
  const addNewQuestion = () => {
    if (firstQuestionId === 0) {
      props.setQuestionChangeComponent(<NoQuestions />);
    } else {
      quizQuestionId(firstQuestionId);
    }
    setNavbarActiveTab("questions");
  };
  return (
    <>
      <div className="main-edit-quiz-sidebar">
        <p className="title">Lets get your quiz set up.</p>
        <ul className="info-list">
          <li className="info">Give it a Name </li>
          <li className="info">
            Add Your Logo <span className="optional">(Optional)</span>{" "}
          </li>
          <li className="info">
            Choose a Picture that Shows What Your Quiz is About{" "}
            <span className="optional">(Optional)</span>{" "}
          </li>
          <li className="info">
            Tell Them About It <span className="optional">(Optional)</span>
          </li>
          <li className="info">
            Personalize the Start Button{" "}
            <span className="optional">(Optional)</span>
          </li>
        </ul>
        <button className="btn-next" onClick={addNewQuestion}>
          Next: Add Questions
        </button>
      </div>
    </>
  );
} 

export function SidebarNoQuestion(props) {
  return (
    <div className="main-sidebar-no-questions">
      <p className="title">Now, let's add some questions</p>
      <p className="title-info">
        Select the type of question you would like to add.
      </p>
      <div className="question-pop">
        <AddQuestionPopup
          setQuestions={props.setQuestions}
          questions={props.questions}
        />
      </div>
    </div>
  );
}

export default function Sidebar(props) {
  const { quizId } = useParams();
  const [questions, setQuestions_] = useState([]);
  const setQuestions = useSelector((state) => state.setQuestions);
  const isQuestionPopupOpen = useSelector((state) => state.isQuestionPopupOpen);
  const dispatch = useDispatch();
  const {
    quizQuestionId,
    setIsQuestionPopupOpen,
    setFirstQuestionId,
    setNavbarActiveTab,
    setQuizTitle,
    setQuizQuestions,
  } = bindActionCreators(actionCreators, dispatch);
  const [quizInfo, setQuizInfo] = useState();
  const firstQuestionId = useSelector((state) => state.firstQuestionId);
  const navbarActiveTab = useSelector((state) => state.navbarActiveTab);
  const quizTitle = useSelector((state) => state.quizTitle);
  const isFromCreateQuiz = useSelector((state) => state.isFromCreateQuiz);
  const quizQuestions = useSelector((state) => state.quizQuestions);

  const handleEditQuestion = (question_number) => {
    quizQuestionId(question_number);
  };

  const OpenQuestionPopup = () => {
    if (firstQuestionId === 0) {
      props.setQuestionChangeComponent(<NoQuestions />);
      // return;
    }
    setIsQuestionPopupOpen(!isQuestionPopupOpen);
  };

  useEffect(() => {
    async function LoadQuestions() {
      try {
        let questions = await QuizApi.get(`quiz/${quizId}/`);
        setQuestions_(questions.data.questions);
        setQuizQuestions(questions.data.questions);
        setQuizTitle(questions.data.quiztitle);
        setQuizInfo(questions.data);
        setFirstQuestionId(questions.data.questions[0]["id"]);
      } catch (error) {
        console.log(error);
        // navigator('/')
      }
    }
    LoadQuestions();
  }, []);

  useEffect(() => {
    setQuizQuestions(questions);
  }, [questions]);

  function handleDrop(draggedId, targetId) {
    const draggedIndex = questions.findIndex(
      (question) => question.id === parseInt(draggedId),
    );
    const targetIndex = questions.findIndex(
      (question) => question.id === targetId,
    );
    let m_questions = [...questions];

    const draggedItem = m_questions.splice(draggedIndex, 1)[0];
    m_questions.splice(targetIndex, 0, draggedItem);
    m_questions.map((question, index) => {
      try {
        QuizApi.patch(`questions/${question.id}/`, {
          question_order: index + 1,
        });
      } catch (error) {
        console.log(error);
      }
    });

    setQuestions_(m_questions);
  }

  return (
    <>
      <div className="main-sidebar">
        <div
          className="quiz-title"
          onClick={() => {
            setNavbarActiveTab("quiz");
            quizQuestionId(false);
            props.setQuestionChangeComponent(<EditQuiz setEditor={props.setEditor} />);
          }}
        >
          <span>
            {quizTitle ? decodeHtmlEntities(quizTitle) : "Add a title"}
          </span>
        </div>
        {!setQuestions && navbarActiveTab === "quiz" ? (
          <EditQuestionPageSidebar
            setQuestionChangeComponent={props.setQuestionChangeComponent}
          />
        ) : firstQuestionId === 0 ? (
          <SidebarNoQuestion
            setQuestions={setQuestions_}
            questions={questions}
          />
        ) : (
          <>
            <div className="add-quiz-btn">
              <button onClick={OpenQuestionPopup}>
                <FontAwesomeIcon
                  icon={faPlus}
                  style={{ marginRight: "10px", verticalAlign: "center" }}
                />
                Add Question
              </button>
              {isQuestionPopupOpen && (
                <AddQuestionPopup
                  setQuestionChangeComponent={props.setQuestionChangeComponent}
                  setIsQuestionPopupOpen={setIsQuestionPopupOpen}
                  setQuestions={setQuestions_}
                  questions={questions}
                />
              )}
            </div>
            <div className="questions-container" id="questions-container">
              <ol>
                {quizQuestions?.map((question, index) => (
                  <li
                    key={question.id}
                    draggable="true"
                    onDragStart={(e) => {
                      e.dataTransfer.setData("text/plain", question.id);
                    }}
                    onDragOver={(e) => {
                      e.preventDefault();
                    }}
                    onDrop={(e) => {
                      e.preventDefault();
                      const draggedId = e.dataTransfer.getData("text");
                      handleDrop(draggedId, question.id);
                    }}
                    className={
                      parseInt(question.id) === parseInt(setQuestions)
                        ? "question-title-side active"
                        : "question-title-side"
                    }
                    onClick={() => {
                      handleEditQuestion(question.id);
                    }}
                    title={convertHtmlToText(question.question_title)}
                    data-question-id={question.id}
                  >
                    <FontAwesomeIcon
                      icon={faGripVertical}
                      className="question-drag-icon"
                      size="sm"
                    />
                    <span> {index + 1}. </span>
                    <span id={`question_id-${question.id}`}>
                      {convertHtmlToText(question.question_title)}{" "}
                    </span>
                  </li>
                ))}
              </ol>
            </div>
          </>
        )}
      </div>
    </>
  );
}
