import React, { useEffect, useRef, useState } from "react";
import "../../assets/styles/takeQuiz/offlineUserDialogue.css";
import { RiWifiOffLine } from "react-icons/ri";
import { AiOutlineLoading } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";

export default function OfflineUserDialogue(props) {
  const [isOffline, setIsOffline] = useState(false);
  const [isRetryLoading, setIsRetryLoading] = useState(false);
  const quizBrowserSettings = useSelector((state) => state.quizBrowserSettings);
  const quizGeneralSettings = useSelector((state) => state.quizGeneralSettings);
  const dispatch = useDispatch();
  const { setTakeQuizQuestion, setIsLoading, setIsTimeOutForceSubmit } = bindActionCreators(
    actionCreators,
    dispatch,
  );
  const isTimeOutForceSubmit = useSelector(
    (state) => state.isTimeOutForceSubmit,
  );
  const timeoutId = useRef(null);
  const wasOffline = useRef(false);

  useEffect(() => {
    const handleOffline = () => {
      console.log("offline");
      setIsOffline(true);
      wasOffline.current = true;
      props?.setIsMouseLeaveOn(false);
      props?.setIsTabChanging(false);

      timeoutId.current = setTimeout(
        () => {
          if (wasOffline.current) {
            setTakeQuizQuestion({
              custommessage: quizGeneralSettings?.custom_message,
              passcustommessage: "Congratulation! You passed",
              failcustommessage: "Sorry! You did not pass",
              closebutton: "Close",
              takenextquizbutton: "Take next quiz",
              showcustommsg: true,
              showanswerreport: false,
              question_type: "end_quiz",
              is_result: "fail",
              show_next_quiz: false,
              next_quiz_id: 2,
              transition: "manual",
              progress_option: "any",
              next_quiz_time: 60,
              next_quiz_title: "second quiz",
              next_quiz_total_questions: 3,
              user_data: {
                quizid: 1,
                name: " ",
                email: " ",
                phonenumber: "",
                collegename: "",
                additionalquestion: null,
              },
              browser_settings: {
                disableprint: true,
                disablecopy: true,
                disablepasteinessay: false,
                switchingbrowsertabs: true,
                highlightcopypaste: true,
                networksecurity: false,
              },
            });
            setIsOffline(false);
            sessionStorage.clear();
            setIsTimeOutForceSubmit(true);
          }
        },
        1000 * 60 * 10,
      );
    };

    const handleOnline = () => {
      console.log("online");
      if (quizBrowserSettings?.switchingbrowsertabs) {
        props?.setIsMouseLeaveOn(true);
      } else {
        props?.setIsMouseLeaveOn(false);
      }
      setIsOffline(false);
      wasOffline.current = false;
      clearTimeout(timeoutId.current);
    };

    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
      clearTimeout(timeoutId.current);
    };
  }, [quizBrowserSettings, quizGeneralSettings]);

  const handleRetryConnection = () => {
    setIsRetryLoading(true);
    const retryTime = Math.floor(Math.random() * (2000 - 500 + 1)) + 500;
    setTimeout(() => {
      setIsRetryLoading(false);
      props?.setIsTabChanging(false);
      if (navigator.onLine) {
        setIsOffline(false);
        wasOffline.current = false;
        clearTimeout(timeoutId.current);
      } else {
        setIsOffline(true);
      }
    }, retryTime);
  };

  return (
    <>
      {isOffline && (
        <>
          <div className="main-offline-dialogue">
            <div className="dialogue">
              <div className="dialogue-head">
                {isRetryLoading ? (
                  <AiOutlineLoading className="loading" fontSize={"50px"} />
                ) : (
                  <RiWifiOffLine fontSize={"50px"} />
                )}
                <h1>OFFLINE</h1>
              </div>
              <div className="dialogue-body">
                <h3 className="message">
                  Your internet connection has been lost. Please check your
                  connection. The quiz will resume automatically once a stable
                  connection is detected.
                </h3>
                <button
                  className="btn-retry-connection"
                  onClick={handleRetryConnection}
                >
                  Retry
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
