import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../assets/styles/commonQuestions.css";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../state";
import {
  handleDeleteQuestion,
  handleQuestionImageUpload,
  handleQuestionSubmit,
} from "../../../utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { getHTMLtoText } from "../../../utils/generalHelp";
import TextEditor from "../../Common/TextEditor";

export default function TrueFalse(props) {
  const [propsValues, setPropsValues] = useState({ ...props.data });
  const imagePath = useSelector((state) => state.imagePath);

  const setQuestions = useSelector((state) => state.setQuestions);
  const isExplanationOn = useSelector((state) => state.isExplanationOn);
  const dispatch = useDispatch();
  const { setImagePath } = bindActionCreators(actionCreators, dispatch);

  const handleAnswerChange = (event) => {
    setPropsValues({ ...propsValues, correct_answers: [event.target.value] });
    handleQuestionSubmit(setQuestions, {
      correct_answers: [event.target.value],
    });
  };

  const handleExplanationBlur = (event) => {
    handleQuestionSubmit(setQuestions, {
      explanation_text: event.target.innerHTML,
    });
  };

  // Function to remove extra CSS from pasted content
  const removeExtraCss = (element) => {
    const elementTree = element.getElementsByTagName("*");

    for (let i = 0; i < elementTree.length; i++) {
      const ele = elementTree[i];
      if (ele.tagName === "INPUT") {
        continue;
      }
      ele.removeAttribute("style");
      ele.removeAttribute("class");
    }
  };

  // Handle change event for contenteditable elements
  const handleContentChange = (event, from) => {
    let value = event.target.value;
    let tempElement = document.createElement("div");
    tempElement.innerHTML = value;

    removeExtraCss(tempElement);

    let cleanedText = tempElement.innerHTML.trim();

    switch (from) {
      case "question_title":
        setPropsValues((prevValues) => ({
          ...prevValues,
          question_title: cleanedText,
        }));
        break;
      case "explanation_text":
        setPropsValues((prevValues) => ({
          ...prevValues,
          explanation_text: cleanedText,
        }));
        break;
      default:
        break;
    }
  };

  const handleQuestionTitleChange = (currentContent) => {
    try {
      let element = document.getElementById(`question_id-${setQuestions}`);

      const tempEle = document.createElement('div')

      tempEle.innerHTML = currentContent;

      element.innerText = tempEle.innerText
    } catch (error) {
      console.log(error);
    } 
  };


  return (
    <>
      <div className="question-main-container">
        <div className="question-title">
          <div className="question-title-container">
            <div className="question-title-head">
              True or False Question
              <span className="total-questions-of">1/10</span>
            </div>

            <TextEditor placeholder={"Write your question here..."} setEditor={props.setEditor} initialContent={propsValues.question_title} onContentChange={(currentContent) => {
              setPropsValues((prev) => {
                handleQuestionSubmit(setQuestions, {
                  question_title: currentContent,
                });
                handleQuestionTitleChange(currentContent);
                return {
                  ...prev,
                  question_title: currentContent,
                }
              });
            }} />
          </div>

          <FontAwesomeIcon
            icon={faTrashCan}
            className="question-delete-icon"
            size="xl"
            onClick={() => {
              handleDeleteQuestion(setQuestions);
            }}
          />
        </div>
        {getHTMLtoText(propsValues["question_title"]) === "" && (
          <p className="question-title-required">
            Please enter a title for your question to proceed
          </p>
        )}

        {imagePath && (
          <div className="upload-img">
            <div className="upload-img-div">
              <button
                className="btn-remove-img"
                onClick={() => {
                  handleQuestionImageUpload({
                    image: "",
                    question_id: setQuestions,
                  });
                }}
              >
                Remove
              </button>
              <img className="question-image" src={imagePath} alt="" />
            </div>
          </div>
        )}
        <div className="question-choices">
          <div className="question-choices-head">Select Correct Answer</div>
          <div className="question-choices-body">
            {Object.entries(propsValues["options"]).map(
              ([key, value], index) => (
                <div className="choice" key={index}>
                  <span>
                    <input
                      type="radio"
                      name="choice"
                      className="btn-radio"
                      value={key}
                      checked={propsValues["correct_answers"].includes(key)}
                      onChange={handleAnswerChange}
                    />
                    <div className="input-ans">{value}</div>
                  </span>
                </div>
              ),
            )}
          </div>
        </div>
        {isExplanationOn && (
          <div className="explanation">
            <div className="explanation-head">
              Add Explanation for Answer (Optional)
            </div>

            <TextEditor minHeight={"100px"} placeholder={`Type Explanation here...`} setEditor={props.setEditor} initialContent={propsValues["explanation_text"]} onContentChange={(currentContent) => {
              handleQuestionSubmit(setQuestions, {
                explanation_text: currentContent,
              });
              setPropsValues({
                ...propsValues,
                explanation_text: currentContent,
              });
            }} />
          </div>
        )}
      </div>
    </>
  );
}
