import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import {
  handleDeleteQuestion,
  handleQuestionImageUpload,
  handleQuestionSubmit,
} from "../../../utils/helpers";
import TextEditor from "../../Common/TextEditor";
import { getHTMLtoText } from "../../../utils/generalHelp";
import convertHtmlToText from "../../../utils/convertHtmlToText";

export default function MultipleChoice(props) {

  const defaultOptions = { opt_1: "", opt_2: "" };
  const [propsValues, setPropsValues] = useState({
    ...props.data,
    options: props.data.options || defaultOptions,
  });
  const [optionMoreThanTwo, setOptionMoreThanTwo] = useState(false);
  const isExplanationOn = useSelector((state) => state.isExplanationOn);
  const imagePath = useSelector((state) => state.imagePath);

  const setQuestions = useSelector((state) => state.setQuestions);

  useEffect(() => {
    if (!propsValues.options) {
      setPropsValues((prev) => ({ ...prev, options: defaultOptions }));
    }
  }, [propsValues.options]);

  const handleAnswerChange = (event) => {
    const selectedValue = event.target.value;
    setPropsValues((prev) => ({
      ...prev,
      correct_answers: [selectedValue],
    }));
    handleQuestionSubmit(setQuestions, {
      correct_answers: [selectedValue],
    });
  };

  const handleAddAdditionalOptions = () => {
    const current_option_count = Object.keys(propsValues.options).length + 1;
    const newKey = `opt_${current_option_count}`;
    setPropsValues((prev) => ({
      ...prev,
      options: { ...prev.options, [newKey]: "" },
    }));
  };

  const removeOptions = (key) => {
    setPropsValues((prev) => {
      const { [key]: removedOption, ...restOptions } = prev.options;
      handleQuestionSubmit(setQuestions, {
        options: restOptions,
        correct_answers: [...prev.correct_answers],
      });
      return { ...prev, options: restOptions };
    });
  };

  useEffect(() => {
    const opt_list = Object.values(propsValues.options || {});
    const fill_count = opt_list.filter((opt) => opt && opt.length >= 1).length;
    setOptionMoreThanTwo(fill_count > 1);
  }, [propsValues.options]);


  const handleQuestionTitleChange = (currentContent) => {
    try {
      let element = document.getElementById(`question_id-${setQuestions}`);
      const htmlToText = convertHtmlToText(currentContent);
      element.innerText = htmlToText;
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <div className="main-multiple-choice-questions question-main-container">
      <div className="question-title">
        <div className="question-title-container">
          <div className="question-title-head">
            Multiple choice Question
            <span className="total-questions-of">1/10</span>
          </div>

          <TextEditor setEditor={props.setEditor} placeholder={"Write your question here..."} initialContent={propsValues.question_title} onContentChange={(currentContent) => {
            setPropsValues((prev) => {
              handleQuestionSubmit(setQuestions, {
                question_title: currentContent,
              });
              handleQuestionTitleChange(currentContent);
              return {
                ...prev,
                question_title: currentContent,
              }
            });
          }} />
        </div>

        <FontAwesomeIcon
          icon={faTrashCan}
          className="question-delete-icon"
          size="xl"
          onClick={() => handleDeleteQuestion(setQuestions)}
        />
      </div>

      {getHTMLtoText(propsValues.question_title) === "" && (
        <p className="question-title-required">
          Please enter a title for your question to proceed
        </p>
      )}

      {imagePath && (
        <div className="upload-img">
          <div className="upload-img-div">
            <button
              className="btn-remove-img"
              onClick={() => {
                handleQuestionImageUpload({
                  image: "",
                  question_id: setQuestions,
                });
              }}
            >
              Remove
            </button>
            <img className="question-image" src={imagePath} alt="" />
          </div>
        </div>
      )}

      <div className="question-choices">
        <div className="question-choices-head">
          {optionMoreThanTwo ? "Select Correct Answer" : "Enter the Answers"}
        </div>
        <div className="question-choices-body">
          {Object.entries(propsValues.options || {}).map(
            ([key, value], index) => (
              <div className="choice" key={key}>
                <span>
                  <input
                    type="radio"
                    name="choice"
                    className="btn-radio"
                    value={key}
                    checked={propsValues.correct_answers.includes(key)}
                    onChange={handleAnswerChange}
                  />

                  <TextEditor noFocusStyles={true} placeholder={`Choice ${index + 1}`} setEditor={props.setEditor} initialContent={value} onContentChange={(currentContent) => {
                    if (currentContent.trim() !== "") {
                      handleQuestionSubmit(setQuestions, {
                        options: {
                          ...propsValues.options,
                          [key]: currentContent.trim(),
                        },
                      });
                    }
                    setPropsValues((prev) => ({
                      ...prev,
                      options: { ...prev.options, [key]: currentContent },
                    }));
                  }} />
                </span>
                {Object.keys(propsValues.options).length > 2 && (
                  <FontAwesomeIcon
                    icon={faTrashCan}
                    size="lg"
                    className="trash"
                    style={{ color: "#002653" }}
                    onClick={() => removeOptions(key)}
                  />
                )}
              </div>
            ),
          )}
          <button className="btn-with-img" onClick={handleAddAdditionalOptions}>
            <FontAwesomeIcon icon={faPlus} size="lg" /> Add Additional Answer
            Choice
          </button>
        </div>
      </div>

      {isExplanationOn && (
        <div className="explanation">
          <div className="explanation-head">
            Add Explanation for Answer (Optional)
          </div>

          <TextEditor minHeight={"100px"} placeholder={`Type Explanation here...`} setEditor={props.setEditor} initialContent={propsValues["explanation_text"]} onContentChange={(currentContent) => {
            handleQuestionSubmit(setQuestions, {
              explanation_text: currentContent,
            });
            setPropsValues({
              ...propsValues,
              explanation_text: currentContent,
            });
          }} />
        </div>
      )}
    </div>
  );
}
