import '../../assets/styles/components/textEditor.css'

// Importing necessary packages and extensions from Tiptap
import { Color } from '@tiptap/extension-color';
import { TextAlign } from '@tiptap/extension-text-align'; // For text alignment
import { Highlight } from '@tiptap/extension-highlight'; // For highlighter
import { Subscript } from '@tiptap/extension-subscript'; // For subscript
import { Superscript } from '@tiptap/extension-superscript'; // For superscript
import { Underline } from '@tiptap/extension-underline'; // For underline
import { Placeholder } from '@tiptap/extension-placeholder'; // For placeholder
import StarterKit from '@tiptap/starter-kit';

// React and other necessary imports
import React, { useCallback, useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import { EditorContent, useEditor } from '@tiptap/react'; // Tiptap's Editor components
import TextStyle from '@tiptap/extension-text-style';
import DOMPurify from 'dompurify'
import TextFormatter from '../editQuiz/TextFormatter';

const TextEditor = ({ placeholder, separateMenuBar = false, customClass, noFocusStyles = false, width = "100%", height = "100%", onContentChange, setEditor, initialContent = '', wordLimit = 2000, isTitle = false, customPlaceholder = null, minHeight = null, secure = false }) => {
    // Customizable visible options
    const tiptapContainerRef = useRef(null);

    const customSuperscript = Superscript.extend({
        addKeyboardShortcuts() {
            return {
                // ↓ your new keyboard shortcut
                'Mod-Shift-ArrowUp': () => this.editor.commands.toggleSuperscript(),
            }
        },
    })
    const customSubscript = Subscript.extend({
        addKeyboardShortcuts() {
            return {
                // ↓ your new keyboard shortcut
                'Mod-Shift-ArrowDown': () => this.editor.commands.toggleSubscript(),
            }
        },
    })

    const extensions = [
        StarterKit.configure({
            bulletList: { keepMarks: true, keepAttributes: false },
            orderedList: { keepMarks: true, keepAttributes: false },
        }),
        Placeholder.configure({
            placeholder: placeholder ? placeholder : 'Write something here...', // Placeholder text
        }),
        Color.configure({ types: [TextStyle.name] }),
        TextAlign.configure({ types: ['heading', 'paragraph', 'codeBlock'] }), // For text alignment
        Highlight.configure(),

        // Subscript.configure(),
        customSuperscript.configure(),
        customSubscript.configure(),
        // Superscript.configure(),s
        Underline.configure(),
    ];

    const sanitizeContent = (content) => {
        return DOMPurify.sanitize(content);
    };

    const editor = useEditor({
        extensions: extensions,
        content: initialContent || "",
        editorProps: {
            attributes: {
                spellcheck: 'false',
            },
            handleKeyDown: (view, event) => {
                // Prevent Enter key if isTitle is true
                if (isTitle && event.key === 'Enter') {
                    event.preventDefault();
                    return true;
                }

                // Prevent paste keyboard shortcut if secure
                if (secure && (event.ctrlKey || event.metaKey) && event.key === 'v') {
                    event.preventDefault();
                    return true;
                }
                return false;
            },
            handlePaste: (view, event) => {
                // Prevent paste if secure mode is enabled
                if (secure) {
                    event.preventDefault();
                    return true;
                }
                return false;
            },
        },
        onUpdate({ editor }) {
            const html = editor.getHTML();
            // Ensure content is always wrapped in h1
            if (isTitle && !html.includes('<h1')) {
                const content = editor.getText()
                editor.commands.setContent(`<h1 style="text-align: center">${content}</h1>`)
                return
            }

            const plainText = editor.getText();  // Get the plain text content

            // Remove any line breaks if isTitle is true
            if (isTitle) {
                const isTitleText = plainText.replace(/[\r\n]+/g, ' ');
                if (isTitleText !== plainText) {
                    editor.commands.setContent(isTitleText);
                    return;
                }
            }

            const wordCount = plainText.split(/\s+/).filter(Boolean).length;  // Count words (ignores empty strings)

            // Prevent further content if word limit exceeded
            if (wordCount > wordLimit) {
                editor.commands.setContent(html);
                return;
            }

            debouncedContentChange(html);
        },
    });

    const debouncedContentChange = useCallback(
        debounce((content) => {
            const sanitizedContent = sanitizeContent(content); // Sanitize the raw content
            onContentChange(sanitizedContent); // Pass the debounced content to parent
        }, 500),
        [] // This ensures the debounce function is not recreated on each render
    );

    const handleKeyDown = (e) => {
        const plainText = editor.getText();
        const wordCount = plainText.split(/\s+/).filter(Boolean).length; // Count words

        // Prevent typing if word limit is exceeded
        if (wordCount >= wordLimit && e.key !== 'Backspace' && e.key !== 'Delete') {
            e.preventDefault(); // Stop further input (typing)
        }
    };

    const handlePaste = (e) => {
        // Double check paste prevention for secure mode
        if (secure) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }
        
        const plainText = editor.getText();
        const wordCount = plainText.split(/\s+/).filter(Boolean).length;

        // Prevent pasting if word limit is exceeded
        if (wordCount >= wordLimit) {
            e.preventDefault(); // Stop paste action
            return;
        }

        // If isTitle is true, modify pasted content
        if (isTitle) {
            e.preventDefault();
            const pastedText = e.clipboardData.getData('text/plain');
            const isTitleText = pastedText.replace(/[\r\n]+/g, ' ');
            editor.commands.insertContent(isTitleText);
        }
    };

    // Add new handler for copy/cut events
    const handleCopy = (e) => {
        if (secure) {
            e.preventDefault();
            return;
        }
    };

    useEffect(() => {
        if (!noFocusStyles && tiptapContainerRef.current) {
            tiptapContainerRef.current.classList.add('focusStyle');
            const tiptapEditor = tiptapContainerRef.current.querySelector('.tiptap');
            if (height) {
                tiptapEditor.style.height = height;
            }
            if (minHeight) {
                tiptapEditor.style.minHeight = minHeight;
            }
        }
        const handleContainerClick = () => {
            console.log("clicked");
            const html = editor.getHTML();
            // Ensure content is always wrapped in h1
            if (isTitle && !html.includes('<h1')) {
                const content = editor.getText()
                editor.commands.setContent(`<h1 style="text-align: center">${content}</h1>`)
                return
            }
        }

        // Attach listeners for keydown, paste, copy, and cut
        const editorElement = tiptapContainerRef.current.querySelector('.tiptap');
        editorElement.addEventListener('keydown', handleKeyDown);
        editorElement.addEventListener('paste', handlePaste);
        editorElement.addEventListener('copy', handleCopy);
        editorElement.addEventListener('cut', handleCopy); // Use same handler for cut
        tiptapContainerRef.current.addEventListener('click', handleContainerClick);

        // Cleanup listeners when the component is unmounted
        return () => {
            editorElement.removeEventListener('keydown', handleKeyDown);
            editorElement.removeEventListener('paste', handlePaste);
            editorElement.removeEventListener('copy', handleCopy);
            editorElement.removeEventListener('cut', handleCopy);
        };
    }, [wordLimit, noFocusStyles, height, secure]); // Add secure to dependency array

    if (!editor) {
        return null;
    }

    return (
        <main style={{ width: width ? width : "100%" }}>
            <div ref={tiptapContainerRef} className={customClass} id='text-editor-container'>
                {separateMenuBar && <TextFormatter editor={editor} />}
                {!editor.getText() && customPlaceholder && <div className='editor-custom-placeholder'>{customPlaceholder}</div>}
                <EditorContent onFocus={() => !separateMenuBar && setEditor(editor)} editor={editor} />
            </div>
        </main>
    );
};

export default TextEditor;
