import QuizApi from "../../services/QuizApi";
import "../../assets/styles/Navbar.css";
import React, { useEffect, useState } from "react";
import { useLocation, Link, useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import NoQuestions from "../editQuiz/questionTypes/NoQuestions";
import EditQuiz from "../editQuiz/EditQuiz";
import { useNavigate } from "react-router-dom";

export function Navbar(props) {
  const location = useLocation();
  const handleLoginButtonClick = () => {
    const token = Cookies.get("authToken");
    if (token) {
      window.location.href = "/manage";
    } else {
      props.onOpen();
    }
  };
  const dispatch = useDispatch();
  const { setQuizReportsFilter } = bindActionCreators(
    actionCreators,
    dispatch,
  );

  useEffect(() => {
    const resetReportsFilter = {
      start_time: "",
      end_time: "",
      filterValueKey: "ALL",
    }
    setQuizReportsFilter(resetReportsFilter);
  }, []);

  return (
    <div className="quiz_navbar" style={{ justifyContent: "space-between" }}>
      <div className="quiz_navbar-left">
        <div className="quiz_navbar-header">
          <img src="/images/logo.png" alt="Logo" className="logo" />
        </div>
      </div>
      <div className="quiz_navbarright">
        <div className="quiz_navbar-left">
          <ul className="quiz_nav-list">
            <li className="quiz_nav-item">
              <Link
                to="/"
                className={location.pathname === "/" ? "active_nav" : ""}
              >
                Home
              </Link>
            </li>
            <li className="quiz_nav-item">
              <Link to="/#Our_Features">Features</Link>
            </li>
            <li className="quiz_nav-item">
              <Link to="/#Our_Pricing">Pricing</Link>
            </li>
            <li className="quiz_nav-item">
              <Link to="/contact" disabled>
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
        <div className="quiz_navbar-right">
          {!localStorage.getItem("is_first_time") && (
            <button className="nav-button" onClick={props.openSignupPopup}>
              Sign up
            </button>
          )}
          <button onClick={handleLoginButtonClick} className="nav-button">
            Login
          </button>
        </div>
      </div>
    </div>
  );
}

export function ManageQuizNavbar(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const { loginSuccess, setIsFromCreateQuiz, setQuizReportsFilter } = bindActionCreators(
    actionCreators,
    dispatch,
  );

  useEffect(() => {
    setIsFromCreateQuiz(false);
  }, []);

  const navigate = useNavigate();

  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.closest('.User_dropdown') &&
        !event.target.closest('.dropdown-content')
      ) {
        setIsProfileDropdownOpen(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    Cookies.remove("authToken");
    Cookies.remove("user");

    navigate("/");
  };


  const toggleProfileDropdown = (e) => {
    e.stopPropagation();
    console.log("Before toggle:", isProfileDropdownOpen);
    setIsProfileDropdownOpen((prevState) => !prevState);
    console.log("After toggle:", !isProfileDropdownOpen);
  };
  let userData = { user: {} };

  const encodedData = Cookies.get("authToken");
  if (encodedData) {
    const decodedData = decodeURIComponent(encodedData);
    userData = JSON.parse(decodedData);
  }


  const createNewQuiz = async () => {
    try {
      const response = await QuizApi.post("quiz/", {
        user: userData?.user?.id,
        quiztitle: "",
      });
      console.log(response);

      setIsFromCreateQuiz(true);
      navigate(`/quiz/${response.data.id}`);
    } catch (error) {
      console.error("Error creating quiz:", error);
    }
  };

  useEffect(() => {
    const resetReportsFilter = {
      start_time: "",
      end_time: "",
      filterValueKey: "ALL",
    }
    setQuizReportsFilter(resetReportsFilter);
  }, []);

  return (
    <div className="quiz_navbar_main">
      <div className="quiz_navbar-left">
        <div className="quiz_navbar-header">
          <img src="/images/logo.png" alt="Logo" className="logo" />
        </div>
      </div>
      <div className="quiz_navbar_managequiz">
        <div className="quiz_navbar-left">
          <ul className="quiz_nav-list" style={{ alignItems: "center" }}>
            <li className="quiz_nav-item">
              <a href="#" className=" aupgradebtn" onClick={createNewQuiz}>
                Create Quiz
              </a>
            </li>
            <li className="quiz_nav-item">
              <Link
                to="/manage/"
                className={location.pathname === "/manage" ? "active_nav" : ""}
              >
                My Quizzes
              </Link>
            </li>
            <li className="quiz_nav-item">
              <a onClick={props.onOpen}>My Team</a>
            </li>
            <li style={{ display: "flex", alignItems: "center" }}>
              <a href="#" className="aupgradebtn">
                <span className="upgradebtn">Upgrade</span>
              </a>
            </li>
          </ul>
        </div>
        <div className="quiz_navbar-right">
          <li className="User_dropdown">
            <div
              className="profileanchor"
              style={{ textDecoration: "none", color: "#333", cursor: "pointer" }}
              onClick={toggleProfileDropdown}
            >
              <p id="profile_info">
                {" "}
                <img
                  style={{
                    filter: "brightness(1.5) invert(1)",
                    width: "20px",
                  }}
                  src="/images/user.png"
                  alt=""
                ></img>
                <span id="display_name">
                  &nbsp;&nbsp;{userData.user.first_name}&nbsp;&nbsp;
                </span>
                <img src="/images/dropdownarrow.png" alt="" />
              </p>
              {isProfileDropdownOpen}
            </div>
            {isProfileDropdownOpen && (
              <div className={`dropdown-content ${isProfileDropdownOpen ? 'open' : ''}`} id="dropdown-content">
                <Link to="/account-details/">{userData.user.first_name}</Link>
                <Link
                  to="/account-details/"
                  style={{ borderBottom: "1px solid #a2c3fda1" }}
                >
                  {userData.user.username}
                </Link>
                <Link to="/manage/" id="myquiz">
                  My Quizzes
                </Link>
                <Link to="/manage/" id="myquiz">
                  Subscription Plan
                </Link>
                <Link to="/manage/" id="myquiz">
                  Account Settings
                </Link>
                <Link to="/under-development/" id="help">
                  Help
                </Link>
                <a
                  onClick={handleLogout}
                  id="logout"
                  style={{ color: "black", cursor: "pointer" }}
                >
                  Logout
                </a>
              </div>
            )}
          </li>
        </div>
      </div>
    </div>
  );
}

export function MainNavbar(props) {
  const Setting_pages = [
    "/general-settings",
    "/scoring-option",
    "/quiz-tracking",
    "/security-settings",
    "/smart-proctoring-system",
    "/lead-integration",
  ];
  const { quizId } = useParams();
  const pagename = "/quiz-title/";
  const location = useLocation();
  const dispatch = useDispatch();
  const { loginSuccess } = bindActionCreators(actionCreators, dispatch);
  const setQuestions = useSelector((state) => state.setQuestions);
  const { quizQuestionId, setNavbarActiveTab } = bindActionCreators(
    actionCreators,
    dispatch,
  );
  const firstQuestionId = useSelector((state) => state.firstQuestionId);
  const navbarActiveTab = useSelector((state) => state.navbarActiveTab);
  const quizTitle = useSelector((state) => state.quizTitle);
  const isFromCreateQuiz = useSelector((state) => state.isFromCreateQuiz);
  const { id } = useParams();
  const handleQuestionClick = () => {
    console.log("isFromCreateQuiz", isFromCreateQuiz);

    if (!isFromCreateQuiz && props.setQuestionChangeComponent) {
      firstQuestionId === 0
        ? props.setQuestionChangeComponent(<NoQuestions />)
        : quizQuestionId(firstQuestionId);
      setNavbarActiveTab("questions");
    } else {
      location.pathname.split("/")[1] !== "quiz" &&
        navigate(`/quiz/${quizId}/?is_q=1`);
    }
  };

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    Cookies.remove("authToken");
    Cookies.remove("user");

    navigate("/");
  };

  let userData = { user: {} };
  const encodedData = Cookies.get("authToken");
  if (encodedData) {
    const decodedData = decodeURIComponent(encodedData);
    userData = JSON.parse(decodedData);
  }
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.closest('.User_dropdown') &&
        !event.target.closest('.dropdown-content')
      ) {
        setIsProfileDropdownOpen(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const toggleDropdown = () => {
    console.log("toggleDropdown");

    setDropdownVisible((prevVisible) => !prevVisible);
  };

  useEffect(() => {
    console.log("isDropdownVisible", isDropdownVisible);
  }, [isDropdownVisible]);
  
  const toggleProfileDropdown = (e) => {
    e.stopPropagation();
    console.log("Before toggle:", isProfileDropdownOpen);
    setIsProfileDropdownOpen((prevState) => !prevState);
    console.log("After toggle:", !isProfileDropdownOpen);
  };
  useEffect(() => {
    console.log(isProfileDropdownOpen);
  }, [isProfileDropdownOpen]);

  const gotoQuizDetails = () => {
    if (typeof props.setQuestionChangeComponent === "undefined") {
      setNavbarActiveTab("quiz");
      quizQuestionId(false);
      return;
    }
    if (firstQuestionId === 0) {
      console.log(props.setEditor);

      props.setQuestionChangeComponent(<EditQuiz setEditor={props.setEditor} />);
    } else {
      quizQuestionId(false);
    }
    setNavbarActiveTab("quiz");
  };

  const determineActiveClass = (substring) =>
    location.pathname.includes(substring) ? "active_nav" : "";

  useEffect(() => {
    const handleMouseDown = (e) => {
      if (!e.target.closest('#performanceanalysis')) {
        setDropdownVisible(false);
      }
    };
    document.addEventListener("mousedown", handleMouseDown);
    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
    };
  }, []);

  return (
    <div id="topheader" className="fixed">
      <div className="quiz_navbar">
        <div className="quiz_navbar-left">
          <div className="quiz_navbar-header">
            <Link to="/manage">
              <img src="/images/logo.png" alt="Logo" className="logo" />
            </Link>
          </div>
        </div>
        <div
          className="quiz_navbarright"
          style={{ width: "100%", alignItems: "center" }}
        >
          <div className="quiz_navbar-left" style={{ width: "90%" }}>
            <ul className="quiz_nav-list">
              <li
                className={`quiz_nav-item ${navbarActiveTab === "quiz" &&
                  !Setting_pages.some((substring) =>
                    location.pathname.includes(substring),
                  ) &&
                  !location.pathname.includes("/general-settings") &&
                  !location.pathname.includes("/view-result-settings") &&
                  !location.pathname.includes("/quiz-result") &&
                  !location.pathname.includes("/quiz-report") &&
                  !location.pathname.includes("/scoring-option") &&
                  !location.pathname.includes("/quiz-tracking") &&
                  !location.pathname.includes("/security-settings") &&
                  !location.pathname.includes("/smart-proctoring-system") &&
                  !location.pathname.includes("/lead-integration")
                  ? "active_nav"
                  : ""
                  }`}
                onClick={gotoQuizDetails}
              >
                <Link to={`/quiz/${quizId}`}>
                  {!isFromCreateQuiz ? "Quiz Details" : "Create Quiz"}
                </Link>
              </li>
              <li
                className={`quiz_nav-item ${navbarActiveTab === "questions" &&
                  !Setting_pages.some((substring) =>
                    location.pathname.includes(substring),
                  ) &&
                  !location.pathname.includes("/general-settings") &&
                  !location.pathname.includes("/view-result-settings") &&
                  !location.pathname.includes("/quiz-result") &&
                  !location.pathname.includes("/quiz-report") &&
                  !location.pathname.includes("/scoring-option") &&
                  !location.pathname.includes("/quiz-tracking") &&
                  !location.pathname.includes("/security-settings") &&
                  !location.pathname.includes("/smart-proctoring-system") &&
                  !location.pathname.includes("/lead-integration")
                  ? "active_nav"
                  : ""
                  }`}
                onClick={handleQuestionClick}
                id="questions-nav-item"
              >
                Questions
              </li>
              <li
                className={`quiz_nav-item ${determineActiveClass(
                  "/general-settings",
                )}`}
              >
                <Link to={`/general-settings/${quizId}`}>Settings</Link>
              </li>
              <li
                className={`quiz_nav-item ${determineActiveClass(
                  "/view-result-settings",
                )}`}
              >
                <Link to={`/view-result-settings/${quizId}`}>Results</Link>
              </li>

              <li
                className={`quiz_nav-item ${determineActiveClass(
                  "/quiz-result",
                )}`}
                id="performanceanalysis"
                style={{ position: "relative" }}
              >
                <span
                  className="dropdownn-label"
                  id="analysislabel"
                  // onMouseOver={handleMouseOver}
                  onClick={toggleDropdown}
                >
                  Analysis <FontAwesomeIcon icon={faSortDown} />
                </span>
                {isDropdownVisible && (
                  <div
                    id="dropdown-content-analysis"
                  // onMouseOver={handleMouseOver}
                  // onMouseLeave={handleMouseLeave}
                  >
                    <Link
                      to={`/quiz-result/${quizId}`}
                      className="analysis_links"
                      id="aquizresult"
                    >
                      Quiz Results
                    </Link>
                    <Link
                      to={`/quiz-stats/${quizId}`}
                      className="analysis_links"
                      id="aquizstats"
                      disabled
                    >
                      Quiz Stats
                    </Link>
                  </div>
                )}
              </li>
            </ul>
          </div>
          <div className="navbar_btns" style={{ width: "30%" }}>
            <Link
              to={`/quiz-title/${quizId}`}
              target="_blank"
              rel="noopener noreferrer"
              className=" aupgradebtn"
            >
              <span className=" upgradebtn">Take Quiz</span>
            </Link>
            <a href="#" className=" aupgradebtn">
              <span className=" upgradebtn">Share Quiz</span>
            </a>
          </div>

          <div className="quiz_navbar-right">
            <li className="User_dropdown">
              <div
                className="profileanchor"
                style={{ textDecoration: "none", color: "#333", cursor: "pointer" }}
                onClick={toggleProfileDropdown}
              >
                <p id="profile_info">
                  {" "}
                  <img
                    style={{
                      filter: "brightness(1.5) invert(1)",
                      width: "20px",
                    }}
                    src="/images/user.png"
                    alt=""
                  ></img>
                  <span id="display_name">
                    &nbsp;&nbsp;{userData.user.first_name}&nbsp;&nbsp;
                  </span>
                  <img src="/images/dropdownarrow.png" alt="" />
                </p>
                {isProfileDropdownOpen}
              </div>
              {isProfileDropdownOpen && (
                <div className={`dropdown-content ${isProfileDropdownOpen ? 'open' : ''}`} id="dropdown-content">
                  <Link to="/account-details/">{userData.user.first_name}</Link>
                  <Link
                    to="/account-details/"
                    style={{ borderBottom: "1px solid #a2c3fda1" }}
                  >
                    {userData.user.username}
                  </Link>
                  <Link to="/manage/" id="myquiz">
                    My Quizzes
                  </Link>
                  <Link to="/manage/" id="myquiz">
                    Subscription Plan
                  </Link>
                  <Link to="/manage/" id="myquiz">
                    Account Settings
                  </Link>
                  <Link to="/under-development/" id="help">
                    Help
                  </Link>
                  <a
                    onClick={handleLogout}
                    id="logout"
                    style={{ color: "black", cursor: "pointer" }}
                  >
                    Logout
                  </a>
                </div>
              )}
            </li>
          </div>
        </div>
      </div>
    </div>
  );
}
