
const convertHtmlToText = (text) => {
    const ele = document.createElement("div");
    ele.innerHTML = text;

    // Remove <br> tags and replace them with a space (or empty string, based on preference)
    ele.querySelectorAll('br').forEach(br => br.replaceWith(' '));

    return ele.innerText;
};

export default convertHtmlToText;
