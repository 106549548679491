import { useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import QuizApi from "../../services/QuizApi";
import { useNavigate, useParams } from "react-router-dom";
import { handleQuizSubmit } from "../../utils/helpers";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import TextEditor from "../Common/TextEditor";

export default function EditQuiz(props) {
  let navigator = useNavigate();
  const [quizdata, setQuizData] = useState();
  const [quizlogo, Setquizlogo] = useState();
  const [titleimage, settitleimage] = useState();
  // const [quizTitle, setQuizTitle] = useState('');
  const quizTitleRef = useRef(null);
  const { quizId } = useParams();
  const dispatch = useDispatch();
  const { setQuizTitle } = bindActionCreators(actionCreators, dispatch);
  const quizTitle = useSelector((state) => state.quizTitle);
  const { setIsFromCreateQuiz } = bindActionCreators(actionCreators, dispatch);
  const isFromCreateQuiz = useSelector((state) => state.isFromCreateQuiz);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showNotCharacterAllowed, setShowNotCharacterAllowed] = useState(false);
  const [quizDataLoading, setQuizDataLoading] = useState(false)
  useEffect(() => {
    async function LoadQuizData() {
      setQuizDataLoading(true)
      try {
        const quiz = await QuizApi.get(`quiz/${quizId}/`);
        console.log(quiz.data);

        setQuizData(quiz.data);
        Setquizlogo(quiz.data.quizlogo);
        settitleimage(quiz.data.titleimage);
        setQuizTitle(quiz.data.quiztitle);
      } catch (error) {
        console.log("evvv>", error);
      }
      setQuizDataLoading(false)
    }
    LoadQuizData();
    if (quizTitleRef.current) {
      quizTitleRef.current.focus();
    }
  }, [quizId]);

  const handleTitleBlur = (updatedTitle) => {
    if (updatedTitle.length < 1 && isFromCreateQuiz) {
      setShowErrorMessage(true);
    } else {
      setShowErrorMessage(false);
    }
    if (updatedTitle.length >= 1 && !isFromCreateQuiz) {
      handleQuizSubmit(quizId, { quiztitle: updatedTitle }).then(
        (data) => {
          setQuizData({ ...data });
          setQuizTitle(data.quiztitle);
        },
      );
    }
  };

  // const handleTitleBlur = (event) => {
  //   if (event.target.value.length < 1 && isFromCreateQuiz) {
  //     setShowErrorMessage(true);
  //   } else {
  //     setShowErrorMessage(false);
  //   }
  //   if (event.target.value.length >= 1 && !isFromCreateQuiz) {
  //     handleQuizSubmit(quizId, { quiztitle: event.target.value }).then(
  //       (data) => {
  //         setQuizData({ ...data });
  //         setQuizTitle(data.quiztitle);
  //       },
  //     );
  //   }
  // };

  const handleDescriptionChange = (updatedDescription) => {
    setQuizData({ ...quizdata, quizdescription: updatedDescription });
    handleQuizSubmit(quizId, { quizdescription: updatedDescription });
  };

  const handleTitleButtonChange = (event) => {
    if (event.target.value.length === 0) {
      event.target.value = "Start";
    }
    setQuizData({ ...quizdata, titlebutton: event.target.value });
    handleQuizSubmit(quizId, { titlebutton: event.target.value });
  };

  const handleQuizLogoChange = (event) => {
    handleQuizSubmit(quizId, { quizlogo: event.target.files[0] }).then(
      (data) => {
        if (data) {
          Setquizlogo(data.quizlogo);
        }
      },
    ).catch((err) => {
      alert("Something went wrong.")
      console.log(err);
    });
  };

  const handleTitleImageChange = (event) => {
    setQuizData((prevData) => ({ ...prevData, titleimage: event.target.files[0] }));
    handleQuizSubmit(quizId, { titleimage: event.target.files[0] }).then(
      (data) => {
        if (data) {
          settitleimage(data.titleimage);
        }
      },
    );
  };

  const removeQuizLogo = () => {
    Setquizlogo("");
    setQuizData((prevData) => ({ ...prevData, quizlogo: "" }));
    handleQuizSubmit(quizId, { quizlogo: "" });
  };

  const removeTitleImage = () => {
    settitleimage("");
    setQuizData((prevData) => ({ ...prevData, titleimage: "" }));
    handleQuizSubmit(quizId, { titleimage: "" });
  };

  const handleTitleChange = (event) => {
    let newTitle = event.currentTarget.innerHTML;
    if (newTitle === "<br>") {
      newTitle = "";
    }
    if (isFromCreateQuiz && quizTitle) {
      setIsFromCreateQuiz(false);
    }

    if (showErrorMessage) {
      setShowErrorMessage(false);
    }

    if (event.target.value.length < 61) {
      setQuizTitle(event.target.value);
    } else {
      setQuizTitle(event.target.value.substring(0, 60));
    }
  };

  const handleStartButtonChange = (event) => {
    setQuizData({ ...quizdata, titlebutton: event.target.event });
  };

  const notAllowCharacter = [
    "<",
    ">",
    "{",
    "}",
    "[",
    "]",
    "^",
    "`",
    "|",
    "\\",
    "/",
  ];

  const handleKeyDown = (event) => {
    setShowNotCharacterAllowed(false);
    if (notAllowCharacter.includes(event.key)) {
      event.preventDefault();
      setShowNotCharacterAllowed(true);
    }
    if (event.currentTarget.innerHTML.length >= 60 && event.keyCode !== 8) {
      event.preventDefault();
      event.stopPropagation();
      event.target.blur();
    }
  };

  return (
    <div className="main-editquiz-container">
      <div className="editing-area">
        <div className="editing-container">
          {
            <>
              {quizlogo ? (
                <div className="quiz-image-container">
                  <button
                    type="button"
                    className="remove-image-button image-upload-button"
                    onClick={removeQuizLogo}
                  >
                    Remove
                  </button>
                  <img src={quizlogo} alt="quizLogo" className="quiz-images" />
                </div>
              ) : (
                <label
                  className="image-upload-button"
                  style={{ width: "200px" }}
                >
                  Upload Your Logo (Optional)
                  <input
                    type="file"
                    name="quizlogo"
                    id=""
                    onChange={handleQuizLogoChange}
                    src={quizlogo}
                    accept="image/*"
                  />
                </label>
              )}
            </>
          }
          <div className="quiz-editor-container">
            {!quizDataLoading && <TextEditor
              customClass={"quiz-title-custom-class"}
              initialContent={`${quizTitle}`}
              onContentChange={handleTitleBlur}
              placeholder="  "
              customPlaceholder={"Add a title"}
              isTitle={true}
              ref={quizTitleRef}
              setEditor={props.setEditor}
              width="80%"
            />}

          </div>
          {showNotCharacterAllowed && (
            <p className="not-char-allowed-msg">
              The quiz title should not contain any of the following characters:{" "}
              {notAllowCharacter.join(", ")}
            </p>
          )}
          {(!quizTitle && !isFromCreateQuiz) || showErrorMessage ? (
            <p className={"quiz-title-error visible"}>
              {" "}
              Please enter a title for your quiz to proceed.
            </p>
          ) : (
            <p className={"quiz-title-error"}>
              {" "}
              Please enter a title for your quiz to proceed.
            </p>
          )}
          {
            <>
              {titleimage ? (
                <div className="quiz-image-container">
                  <button
                    type="button"
                    className="remove-image-button image-upload-button"
                    onClick={removeTitleImage}
                  >
                    Remove
                  </button>
                  <img
                    src={titleimage}
                    alt="quizTitleImage"
                    className="quiz-images"
                  />
                </div>
              ) : (
                <label className="image-upload-button button-title-upload-image">
                  Upload image to display between
                  <br />
                  your quiz title and description (Optional)
                  <input
                    type="file"
                    name="quiztitleimage"
                    id=""
                    onChange={handleTitleImageChange}
                    src={titleimage}
                  />
                </label>
              )}
            </>
          }

          <div className="quiz-editor-container description">
            {/* quizdata?.quizdescription && */}
            {!quizDataLoading && <TextEditor
              initialContent={quizdata?.quizdescription}
              onContentChange={handleDescriptionChange}
              placeholder="Type your description"
              setEditor={props.setEditor}
              minHeight={"100px"}
              width="80%"
            />}
          </div>

          <div className="start-quiz-button-editor-container">
            <input
              className="start-quiz-button-editor"
              onBlur={handleTitleButtonChange}
              onChange={handleStartButtonChange}
              value={quizdata?.titlebutton}
            />
          </div>


        </div>
      </div>
    </div>
  );
}
