import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faIndent } from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import { handleQuestionImageUpload, handleQuestionSubmit } from "../../utils/helpers";
import "../../assets/styles/commonQuestions.css";
// import "../../assets/styles/imageUploadAndExplanation.css";


export default function ImageUploadAndExplanation({ showFileUploadAndExplaination }) {
  const isExplanationOn = useSelector((state) => state.isExplanationOn);
  const dispatch = useDispatch();
  const { setIsExplanationOn, setImagePath } = bindActionCreators(actionCreators, dispatch);
  const imagePath = useSelector((state) => state.imagePath);
  const setQuestions = useSelector((state) => state.setQuestions);

  const tooltip = (
    <Tooltip id="tooltip" className="text-format-image-tooltip">
      Upload image to display between question and answer (optional)
    </Tooltip>
  );

  const handleUploadImage = (event) => {
    handleQuestionImageUpload({
      question_id: setQuestions,
      image: event.target.files[0],
    });
  };

  console.log(showFileUploadAndExplaination);

  return (
    < >
      <div className={imagePath ? "image-show active-tool-bar" : "image-show"}>
        <OverlayTrigger placement="bottom" overlay={tooltip}>
          <label className={"btn-with-img"}>
            <FontAwesomeIcon icon={faImage} size="xl" />
            <p>Upload</p>
            <input
              type="file"
              className="type-file-upload"
              value={""}
              id="file-upload"
              onChange={handleUploadImage}
              accept="image/*"
            />
          </label>
        </OverlayTrigger>
      </div>

      <div
        className={isExplanationOn ? "add-explain active-tool-bar" : "add-explain"}
        title="Provide additional details or reasoning for your answer."
        onClick={() => {
          setIsExplanationOn(!isExplanationOn);
          handleQuestionSubmit(setQuestions, {
            show_explanation: !isExplanationOn,
          });
        }}
      >
        <FontAwesomeIcon size="lg" icon={faIndent} />
        <p className="active-add-to-explain">Add Explanation for answer.</p>
      </div>
    </>
  );
}
