import '../../assets/styles/components/formatText.css';

import React from 'react';

function FormatText({ text }) {
    return (
        <div id='format-text-container' >

            <div dangerouslySetInnerHTML={{ __html: text }} id='tiptap' />
        </div>
    );
}

export default FormatText;
