import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import {
  handleQuestionSubmit,
  handleDeleteQuestion,
  handleQuestionImageUpload,
} from "../../../utils/helpers";
import { getHTMLtoText } from "../../../utils/generalHelp";
import TextEditor from "../../Common/TextEditor";

export default function MultipleResponse(props) {
  const [propsValues, setPropsValues] = useState({ ...props.data });
  const isExplanationOn = useSelector((state) => state.isExplanationOn);
  const setQuestions = useSelector((state) => state.setQuestions);
  const imagePath = useSelector((state) => state.imagePath);
  const [optionMoreThanTwo, setOptionMoreThanTwo] = useState(false);

  const handleAnswerChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setPropsValues((prevValues) => ({
        ...prevValues,
        correct_answers: [...prevValues.correct_answers, value],
      }));
    } else {
      setPropsValues((prevValues) => ({
        ...prevValues,
        correct_answers: prevValues.correct_answers.filter(
          (answer) => answer !== value,
        ),
      }));
    }
  };

  const handleAnswerBlur = () => {
    handleQuestionSubmit(setQuestions, {
      options: { ...propsValues.options },
      correct_answers: [...propsValues.correct_answers],
    });
  };

  const handleAddAdditionalOptions = () => {
    let currentOptionCount = Object.keys(propsValues.options).length + 1;
    while (
      Object.keys(propsValues.options).includes(`opt_${currentOptionCount}`)
    ) {
      currentOptionCount += 1;
    }
    setPropsValues((prevValues) => ({
      ...prevValues,
      options: { ...prevValues.options, [`opt_${currentOptionCount}`]: "" },
    }));
  };

  const removeOptions = (key) => {
    const updatedOptions = { ...propsValues.options };
    delete updatedOptions[key];
    setPropsValues((prevValues) => ({
      ...prevValues,
      options: updatedOptions,
    }));
    handleQuestionSubmit(setQuestions, {
      options: updatedOptions,
      correct_answers: [...propsValues.correct_answers],
    });
  };

  useEffect(() => {
    const optList = Object.values(propsValues.options);
    const filledCount = optList.reduce(
      (count, option) => (option.length > 0 ? count + 1 : count),
      0,
    );
    setOptionMoreThanTwo(filledCount > 1);
  }, [propsValues]);

  const handleQuestionTitleChange = (currentContent) => {
    try {
      let element = document.getElementById(`question_id-${setQuestions}`);

      const tempEle = document.createElement('div')

      tempEle.innerHTML = currentContent;

      element.innerText = tempEle.innerText
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <div className="question-main-container">
      <div className="question-title">
        <div className="question-title-container">
          <div className="question-title-head">
            Multiple Response Question
            <span className="total-questions-of">1/10</span>
          </div>

          <TextEditor placeholder={"Write your question here..."} setEditor={props.setEditor} minHeight={"100px"} initialContent={propsValues.question_title} onContentChange={(currentContent) => {
            setPropsValues((prev) => {
              handleQuestionSubmit(setQuestions, {
                question_title: currentContent,
              });
              handleQuestionTitleChange(currentContent);
              return {
                ...prev,
                question_title: currentContent,
              }
            });
          }} />

        </div>

        <FontAwesomeIcon
          icon={faTrashCan}
          className="question-delete-icon"
          size="xl"
          onClick={() => {
            handleDeleteQuestion(setQuestions);
          }}
        />
      </div>
      {getHTMLtoText(propsValues.question_title) === "" && (
        <p className="question-title-required">
          Please enter a title for your question to proceed
        </p>
      )}

      {imagePath && (
        <div className="upload-img">
          <div className="upload-img-div">
            <button
              className="btn-remove-img"
              onClick={() => {
                handleQuestionImageUpload({
                  image: "",
                  question_id: setQuestions,
                });
              }}
            >
              Remove
            </button>
            <img className="question-image" src={imagePath} alt="" />
          </div>
        </div>
      )}
      <div className="question-choices">
        <div className="question-choices-head">
          {optionMoreThanTwo ? "Select correct answer" : "Enter the answers"}
        </div>
        <div className="question-choices-body">
          {Object.entries(propsValues.options).map(([key, value], index) => (
            <div className="choice" key={key}>
              <span>
                <input
                  type="checkbox"
                  name="choice"
                  className="btn-radio"
                  onBlur={handleAnswerBlur}
                  value={key}
                  checked={propsValues.correct_answers.includes(key)}
                  onChange={handleAnswerChange}
                />

                <TextEditor noFocusStyles={true} placeholder={`Choice ${index + 1}`} setEditor={props.setEditor} initialContent={value} onContentChange={(currentContent) => {
                  if (currentContent.trim() !== "") {
                    handleQuestionSubmit(setQuestions, {
                      options: {
                        ...propsValues.options,
                        [key]: currentContent.trim(),
                      },
                    });
                  }
                  setPropsValues((prev) => ({
                    ...prev,
                    options: { ...prev.options, [key]: currentContent },
                  }));
                }} />
              </span>
              {Object.keys(propsValues.options).length > 1 && (
                <FontAwesomeIcon
                  icon={faTrashCan}
                  size="lg"
                  className="trash"
                  style={{ color: "#002653" }}
                  onClick={() => {
                    removeOptions(key);
                  }}
                />
              )}
            </div>
          ))}
          <button className="btn-with-img" onClick={handleAddAdditionalOptions}>
            <FontAwesomeIcon icon={faPlus} size="lg" /> Add Additional Answer
            Choice
          </button>
        </div>
      </div>

      {isExplanationOn && (
        <div className="explanation">
          <div className="explanation-head">
            Add Explanation for Answer (Optional)
          </div>

          <TextEditor minHeight={"100px"} placeholder={`Type Explanation here...`} setEditor={props.setEditor} initialContent={propsValues["explanation_text"]} onContentChange={(currentContent) => {
            handleQuestionSubmit(setQuestions, {
              explanation_text: currentContent,
            });
            setPropsValues({
              ...propsValues,
              explanation_text: currentContent,
            });
          }} />
        </div>
      )}
    </div>
  );
}
