import { configureStore } from "@reduxjs/toolkit";
import { applyMiddleware } from "redux";
import reducers from "./reducers";
import thunk from "redux-thunk";

export const store = configureStore(
  {
    reducer: reducers
  },

  {},
  applyMiddleware(thunk),
);
