import React, { useEffect, useMemo, useRef, useState } from "react";
import Sidebar from "../Common/QuizSidebar";
import QuizApi from "../../services/QuizApi";
import {
  useLocation,
  useNavigate,
  useParams, 
  useSearchParams,
} from "react-router-dom";
import MultipleChoice from "./questionTypes/MultipleChoice";
import { useDispatch, useSelector } from "react-redux";
import YesNo from "./questionTypes/YesNo";
import EditQuiz from "./EditQuiz";
import TextFormatter from "./TextFormatter";
import TrueFalse from "./questionTypes/TrueFalse";
import MultipleResponse from "./questionTypes/MultipleResponse";
import Essay from "./questionTypes/Essay";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import AddDocument from "./questionTypes/AddDocument";
import SelectFileUploadType from "./SelectFileUploadType";
import { MainNavbar } from "../Common/Navbar";
import ErrorNotificationComponent from "./ErrorNotificationComponent";
import Loading from "./Loading";
import { setNavbarActiveTab } from "../../state/action-creators";
import ImageUploadAndExplanation from "./ImageUploadAndExplanation";
import NoQuestions from "./questionTypes/NoQuestions";

export default function EditQuestions() {
  let quiz_id = useParams().id;
  let navigator = useNavigate();
  const [questionData, setQuestionData] = useState({});
  const setQuestions = useSelector((state) => state.setQuestions);
  const [question_type, setQuestion_type] = useState("");
  const isExplanationOn = useSelector((state) => state.isExplanationOn);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const is_q = searchParams.get("is_q");

  const {
    setIsExplanationOn,
    setImagePath,
    setIsQuestionPopupOpen,
    setFirstQuestionId,
    quizQuestionId,
  } = bindActionCreators(actionCreators, dispatch);
  const isQuestionPopupOpen = useSelector((state) => state.isQuestionPopupOpen);
  const firstQuestionId = useSelector((state) => state.firstQuestionId);
  const [currentEditor, setCurrentEditor] = useState(null)
  const [questionChangeComponent, setQuestionChangeComponent] = useState(
    <EditQuiz setEditor={setCurrentEditor} />,
  );

  const setQuestionComponent = (question_type, question_data) => {
    setIsExplanationOn(question_data["show_explanation"]);
    switch (question_type) {
      case "multiple_choice":
        setQuestionChangeComponent(<MultipleChoice data={question_data} setEditor={setCurrentEditor} />);
        break;
      case "multiple_response":
        setQuestionChangeComponent(<MultipleResponse data={question_data} setEditor={setCurrentEditor} />);
        break;
      case "essay":
        setQuestionChangeComponent(<Essay data={question_data} setEditor={setCurrentEditor} />);
        break;
      case "yes_no":
        setQuestionChangeComponent(<YesNo data={question_data} setEditor={setCurrentEditor} />);
        break;
      case "true_false":
        setQuestionChangeComponent(<TrueFalse data={question_data} setEditor={setCurrentEditor} />);
        break;
      case "add_document":
        setQuestionChangeComponent(<AddDocument data={question_data} setEditor={setCurrentEditor} />);
        break;
      // case "0":
      //   setQuestionChangeComponent(<EditQuiz setEditor={setCurrentEditor} />);
      //   break;
      default:
        setQuestionChangeComponent(<EditQuiz setEditor={setCurrentEditor} />);
        break;
    }
  };


  const location = useLocation();
  const activeComponent = useRef(null);

  useMemo(async () => {
    setQuestionChangeComponent(<Loading />);
    if (setQuestions) {
      try {
        let response = await QuizApi.get(`questions/${setQuestions}/`);
        if (response.data.questions ) {
          
        }
        setQuestionData({ ...response.data });
        setQuestionComponent(response.data.question_type, response.data);
        setImagePath(response?.data?.question_image);
      } catch (error) {
        console.log("error", error);
        setQuestionChangeComponent(<NoQuestions />);
      }
    } else {
      setQuestionChangeComponent(<EditQuiz setEditor={setCurrentEditor} />);
    }
  }, [setQuestions]);

  const handleParentClick = (event) => {
    if (isQuestionPopupOpen) {
      setIsQuestionPopupOpen(false);
    }
  };

  useEffect(() => {
    if (is_q) {
      setFirstQuestionId(is_q);
      quizQuestionId(firstQuestionId);
      document.getElementById("questions-nav-item").click();
      setQuestionChangeComponent(<Loading />);
      setTimeout(() => {
        document.getElementById("questions-nav-item").click();
      }, 1000);
    }
  }, [is_q]);



  return (
    <div onClick={handleParentClick} ref={activeComponent}>
      <MainNavbar setEditor={setCurrentEditor} setQuestionChangeComponent={setQuestionChangeComponent} />
      <div className="main-editquiz-container">
        <Sidebar setEditor={setCurrentEditor} setQuestionChangeComponent={setQuestionChangeComponent} />
        <div style={{ width: "100%", display: "grid", alignContent: "start" }}>
          {questionChangeComponent.type.name !== "NoQuestions" && (
            <TextFormatter editor={currentEditor} showFileUploadAndExplaination={(questionChangeComponent && questionChangeComponent?.type?.name !== "EditQuiz" &&
              questionChangeComponent?.type?.name !== "AddDocument")} />
          )}
          {questionChangeComponent.type.name !== "AddDocument" && (
            <ErrorNotificationComponent />
          )}
          {/* {!setQuestions && <EditQuiz />} */}
          {questionChangeComponent && questionChangeComponent}
          {/* <input
            type="text"
            value={questionData.question}
            onChange={(e) => setQuestionData({ ...questionData, question: e.target.value })}
            onPaste={(event) => handleOnPaste(event, 'question_title')}
          /> */}
        </div>
      </div>
    </div>
  );
}
