import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../state";
import { handleTakeNextQuestion } from "../../../utils/helpers";
import "../../../assets/styles/index.css";
import { BACKEND_URL } from "../../../services/QuizApi";
import FormatText from "../../Common/FormatText";
import TextEditor from "../../Common/TextEditor";

export default function EssayQuestion() {
  const [isFirst, setIsFirst] = useState(true);
  const dispatch = useDispatch();
  const { setQuizProgress, setCompleteQuestion } = bindActionCreators(actionCreators, dispatch);

  const quizGeneralSettings = useSelector((state) => state.quizGeneralSettings);
  const takeQuizQuestion = useSelector((state) => state.takeQuizQuestion);
  const completeQuestion = useSelector((state) => state.completeQuestion);
  const quizResult = useSelector((state) => state.quizResult);
  const isTimeOutForceSubmit = useSelector((state) => state.isTimeOutForceSubmit);
  const isWordLimitExceed = useRef(false);
  const wordCountRef = useRef(null);
  const submitButtonRef = useRef(null);

  const getData = () => {
    const res = JSON.parse(sessionStorage.getItem("essay-ans"));
    const condition = parseInt(res?.key.split("-")[0]) === takeQuizQuestion?.question_id &&
      parseInt(res?.key.split("-")[1]) === quizResult?.id;
    return condition ? res?.value : "";
  };

  const convertHtmlToText = (html) => {
    const ele = document.createElement("div");
    ele.innerHTML = html;
    return ele.innerText;
  };

  const [answerText, setAnswerText] = useState(getData());
  const initialWordCount = answerText ? convertHtmlToText(answerText).split(" ").filter(Boolean).length : 0
  const [currentWordCount, setCurrentWordCount] = useState(initialWordCount);

  const handleSubmit = (event) => {
    console.log(answerText);
    handleTakeNextQuestion(takeQuizQuestion["question_id"], { text: answerText });
    event.preventDefault();
  };

  const handleTextChange = (updatedContent) => {
    setAnswerText(updatedContent);
    const words = convertHtmlToText(updatedContent);


    sessionStorage.setItem("essay-ans", JSON.stringify({
      key: `${takeQuizQuestion?.question_id}-${quizResult?.id}`,
      value: updatedContent,
    }));

    const maxLimit = parseInt(takeQuizQuestion?.options?.max_word) || 200;
    const wordCount = words.split(/\s+/).filter(Boolean).length;

    if (wordCount <= maxLimit) {
      setCurrentWordCount(wordCount);
      isWordLimitExceed.current = false;
    } else {
      isWordLimitExceed.current = true;
    }
  };

  const handleEssayKeyDown = (e) => {
    if (isFirst) {
      setQuizProgress((completeQuestion / quizGeneralSettings.selectnumberofquestion) * 100);
      sessionStorage.setItem("quizProgress", (completeQuestion / quizGeneralSettings.selectnumberofquestion) * 100);
      setIsFirst(false);
    }

    const maxWordCount = takeQuizQuestion?.options?.max_word || 200;

    if (e.key === " " || e.key === "Enter") {
      const essayWordCount = parseInt(wordCountRef.current.innerHTML);
      if (essayWordCount >= maxWordCount) {
        e.preventDefault();
        return false;
      }
    }

    return true;
  };

  useEffect(() => {
    if (isTimeOutForceSubmit) {
      submitButtonRef?.current?.click();
    }
  }, [isTimeOutForceSubmit]);

  useEffect(() => {
    console.log(answerText);
  }, [answerText]);

  return (
    <div className="quizcontainer">
      <div className="quizcontainer">
        <div className="take-common-questions">
          <h1 className="format-question-title">
            <FormatText text={takeQuizQuestion["question_title"]} />
          </h1>
          {takeQuizQuestion?.question_image && (
            <div className="between-img">
              <img src={BACKEND_URL + "media/" + takeQuizQuestion?.question_image} alt="" />
            </div>
          )}

          <div className="options-div">
            <div onKeyDown={handleEssayKeyDown}>
              <TextEditor
                separateMenuBar={true}
                secure={true}
                initialContent={answerText}
                placeholder={convertHtmlToText(takeQuizQuestion?.options?.description)}
                height="300px"
                wordLimit={takeQuizQuestion?.options?.max_word || 200}
                onContentChange={handleTextChange}
              />
            </div>
            <div
              className="word-limit"
              style={{
                color: currentWordCount >= takeQuizQuestion?.options?.max_word ? "red" : "black",
              }}
            >
              <span ref={wordCountRef}>{currentWordCount}</span>/
              {takeQuizQuestion?.options?.max_word || 200} Words
            </div>
          </div>
          {takeQuizQuestion["show_explanation"] && (
            <div className="question-explanation">
              <div className="explanation-title">Explanation: </div>
              <div className="explanation-body">
                <FormatText text={takeQuizQuestion["explanation_text"]} />
              </div>
            </div>
          )}
          <button
            ref={submitButtonRef}
            className={
              answerText.length > 0 && answerText !== "<p></p>" ? "btn-submit" : "btn-submit btn-disable"
            }
            onClick={handleSubmit}
          >
            {quizGeneralSettings.selectnumberofquestion === completeQuestion
              ? "Submit"
              : "Next Question"}
          </button>
        </div>
      </div>
    </div>
  );
}
